import AuthService from '../services/auth.service'

const initialState = {
    table: '',
    cart: [],
    user: ''
}
export const pos = {
    namespaced: true,
    state: initialState,
    actions: {
        setTable({ commit }, table) {
            commit('setTable', table)
        },
        setUser({ commit }, user) {
            commit('setUser', user)
        },
        addToCart({ commit }, item) {
            commit('addToCart', item)
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },

    },
    mutations: {
        // handler: () => { },
        setTable(state, table) {
            state.table = table
        },
        setUser(state, user) {
            state.user = user
        },
        addToCart(state, item) {
            if (state.cart.includes(item)) {
                let existingElement = state.cart.find(element => element.id == item.id)
                item.sub_total = item.quantity * item.price
                existingElement.quantity = existingElement.quantity + 1
                existingElement.sub_total = existingElement.sub_total + (item.quantity * item.price)
            } else {
                item.quantity = 1
                item.sub_total = item.quantity * item.price
                state.cart.push(item)
            }
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },

    },
    getters: {
        user: (state) => state.user
    }

}