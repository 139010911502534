<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div class="">
        <div
          class="card px-2 py-0"
          id="ordersTable"
          data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
        >
          <div class="card-header">
            <div class="row flex-between-center">
              <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
                <!-- <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Orders</h5> -->

                <div class="dropdown float-left">
                  <button
                    class="btn btn-falcon-default text-600 btn-sm dropdown-toggle dropdown-caret-none"
                    type="button"
                    id="email-filter"
                    data-bs-toggle="dropdown"
                    data-boundary="viewport"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span data-view-title="data-view-title"
                      >Select Tickets/Orders</span
                    ><span class="fas fa-sort ms-2 fs--1"></span>
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-end border py-2"
                    aria-labelledby="email-filter"
                  >
                    <a
                      class="active dropdown-item d-flex justify-content-between"
                      @click="getClosedOrders"
                      data-fc-view="dayGridMonth"
                      >Closed Tickets<span class="icon-check"
                        ><span
                          class="fas fa-check"
                          data-fa-transform="down-4 shrink-4"
                        ></span></span></a
                    ><a
                      class="dropdown-item d-flex justify-content-between"
                      @click="getVoidedOrders"
                      data-fc-view="timeGridWeek"
                      >Void Tickets<span class="icon-check"
                        ><span
                          class="fas fa-check"
                          data-fa-transform="down-4 shrink-4"
                        ></span></span
                    ></a>
                    <a
                      class="dropdown-item d-flex justify-content-between"
                      @click="getOpenOrders"
                      data-fc-view="timeGridWeek"
                      >Open Tickets<span class="icon-check"
                        ><span
                          class="fas fa-check"
                          data-fa-transform="down-4 shrink-4"
                        ></span></span
                    ></a>
                    <a
                      class="dropdown-item d-flex justify-content-between"
                      @click="getAllOrders"
                      data-fc-view="timeGridWeek"
                      >All Tickets<span class="icon-check"
                        ><span
                          class="fas fa-check"
                          data-fa-transform="down-4 shrink-4"
                        ></span></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                <div class="d-none" id="orders-bulk-actions">
                  <div class="d-flex">
                    <select
                      class="form-select form-select-sm"
                      aria-label="Bulk actions"
                    >
                      <option selected="">Bulk actions</option>
                      <option value="Refund">Refund</option>
                      <option value="Delete">Delete</option>
                      <option value="Archive">Archive</option></select
                    ><button
                      class="btn btn-falcon-default btn-sm ms-2"
                      type="button"
                    >
                      Apply
                    </button>
                  </div>
                </div>

                <div id="orders-actions">
                  <!-- <button
                    class="btn btn-falcon-default btn-sm mx-2"
                    type="button"
                  >
                    <span
                      class="fas fa-filter"
                      data-fa-transform="shrink-3 down-2"
                    ></span
                    ><span class="d-none d-sm-inline-block ms-1">Filter</span>
                  </button>
                  <button class="btn btn-falcon-default btn-sm" type="button">
                    <span
                      class="fas fa-external-link-alt"
                      data-fa-transform="shrink-3 down-2"
                    ></span
                    ><span class="d-none d-sm-inline-block ms-1">Export</span>
                  </button> -->
                  <button
                    :disabled="selected.length <= 1"
                    class="btn btn-primary btn-sm mx-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#mergeModal"
                  >
                    <span
                      class="fas fa-object-group"
                      data-fa-transform="shrink-3 down-2"
                    ></span
                    ><span class="d-none d-sm-inline-block ms-1"
                      >Merge Tickets</span
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive scrollbar">
              <table
                class="table table-sm table-striped fs--1 mb-0 overflow-hidden"
              >
                <thead class="bg-200 text-900">
                  <tr>
                    <th>
                      <div
                        class="form-check fs-0 mb-0 d-flex align-items-center"
                      >
                        <input
                          class="form-check-input"
                          id="checkbox-bulk-customers-select"
                          type="checkbox"
                          data-bulk-select='{"body":"table-orders-body","actions":"orders-bulk-actions","replacedElement":"orders-actions"}'
                        />
                      </div>
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap"
                      data-sort="order"
                      style="width: 15%"
                    >
                      Order
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap pe-7"
                      data-sort="date"
                      @click="sortBy('date')"
                      style="width: 15%"
                    >
                      Date
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap"
                      data-sort="address"
                      style="width: 20%"
                    >
                      Customer Detail
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap text-center"
                      data-sort="status"
                    >
                      Status
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap text-end"
                      data-sort="amount"
                      style="width: 15%"
                    >
                      Due Amount
                    </th>
                    <th
                      class="sort pe-1 align-middle white-space-nowrap text-end"
                      data-sort="amount"
                      style="width: 15%"
                      @click="sortBy('amount')"
                    >
                      Total Amount
                    </th>
                    <th
                      class="sort pe-1 ml-auto white-space-nowrap text-end"
                      data-sort="amount"
                      style="width: 10%"
                    >
                      Actions
                    </th>
                    <th class="no-sort"></th>
                  </tr>
                </thead>
                <tbody
                  :key="orders_table_key"
                  class="list"
                  id="table-orders-body"
                >
                  <tr
                    class="btn-reveal-trigger"
                    v-for="(order, i) in resources"
                    :key="i"
                  >
                    <td class="align-middle" style="width: 28px">
                      <div
                        class="form-check fs-0 mb-0 d-flex align-items-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="checkbox-0"
                          :value="order.ID"
                          @click="updateSelected"
                          data-bulk-select-row="data-bulk-select-row"
                        />
                      </div>
                    </td>
                    <td class="order py-2 align-middle white-space-nowrap">
                      <a href="order-details.html">
                        <strong>#{{ order.ID }}</strong></a
                      >
                      by <strong>{{ order.owner.FIRST_NAME }}</strong
                      ><br /><a :href="`mailto:${order.owner.email}`">{{
                        order.owner.email
                      }}</a>
                    </td>
                    <td class="date py-2 align-middle">
                      {{ order.CREATED_AT }}
                    </td>
                    <td class="address py-2 align-middle white-space-nowrap">
                      {{ order.customer.FIRST_NAME }}
                      {{ order.customer.LAST_NAME }}
                      <p class="mb-0 text-500">{{ order.TICKET_TYPE }}</p>
                    </td>
                    <td
                      class="status py-2 align-middle text-center fs-0 white-space-nowrap"
                    >
                      <span
                        v-if="order.STATUS == 'DONE'"
                        class="badge badge rounded-pill d-block badge-soft-secondary"
                        >On Hold
                        <span
                          class="ms-1 fas fa-ban"
                          data-fa-transform="shrink-2"
                        >
                        </span>
                      </span>
                      <span
                        v-if="order.STATUS == 'VOID' || order.STATUS == 'VOIDED'"
                        class="badge badge rounded-pill d-block badge-soft-danger"
                      >
                        Voided
                        <span
                          class="ms-1 fas fa-stream"
                          data-fa-transform="shrink-2"
                        >
                        </span>
                      </span>
                      <span
                        v-if="order.STATUS == 'OPEN'"
                        class="badge badge rounded-pill d-block badge-soft-primary"
                      >
                        Open
                        <span
                          class="ms-1 fas fa-redo"
                          data-fa-transform="shrink-2"
                        ></span>
                      </span><span
                      v-if="order.STATUS == 'GIFT' || order.STATUS == 'GIFTED'"
                      class="badge badge rounded-pill d-block badge-soft-info"
                    >
                      Gifted
                      <span
                        class="ms-1 fas fa-redo"
                        data-fa-transform="shrink-2"
                      ></span>
                    </span>
                      <span
                        v-if="order.STATUS == 'CLOSED'"
                        class="badge badge rounded-pill d-block badge-soft-success"
                        >Closed<span
                          class="ms-1 fas fa-check"
                          data-fa-transform="shrink-2"
                        ></span
                      ></span>
                    </td>
                    <td
                      class="amount py-2 align-middle text-end text-xs badge-soft-success"
                    >
                      {{ formatToCurrency(order.DUE_AMOUNT) }}
                    </td>
                    <td
                      class="amount py-2 align-middle text-end fs-0 fw-medium"
                    >
                      {{ formatToCurrency(order.TOTAL_PRICE) }}
                    </td>
                    <td class="py-2 align-middle white-space-nowrap text-end">
                      <div class="dropdown font-sans-serif position-static">
                        <button
                          class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                          type="button"
                          id="order-dropdown-0"
                          data-bs-toggle="dropdown"
                          data-boundary="viewport"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span class="fas fa-ellipsis-h fs--1"></span>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end border py-0"
                          aria-labelledby="order-dropdown-0"
                        >
                          <div class="py-2">
                            <a
                              class="dropdown-item"
                              type="button"
                              data-bs-toggle="modal"
                              :data-bs-target="'#staticBackdrop' + order.ID"
                            >
                              Preview
                            </a>
                            <a
                              @click="printReceipt(order)"
                              class="dropdown-item text-primary"
                              href="javascript:;"
                              >Print Receipt</a
                            >
                            <a
                              role="button"
                              class="dropdown-item text-warning"
                              @click="printOrder(order)"
                              >Print Order</a
                            >
                            <a
                              class="dropdown-item text-info"
                              role="button"
                              @click="printBill(order)"
                              >Print Bill</a
                            >
                            <a
                              class="dropdown-item"
                              role="button"
                              @click="closeTicket(order)"
                              >Close Ticket</a
                            >
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item text-danger"
                              role="button"
                              @click="voidTicket(order)"
                              >Void</a
                            >
                            <a
                              class="dropdown-item text-success"
                              role="button"
                              @click="giftTicket(order)"
                              >Gift</a
                            >
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex align-items-center justify-content-center">
              <button
                class="btn btn-sm btn-falcon-default me-1"
                type="button"
                title="Previous"
                data-list-pagination="prev"
              >
                <span class="fas fa-chevron-left"></span>
              </button>
              <ul class="pagination mb-0"></ul>
              <button
                class="btn btn-sm btn-falcon-default ms-1"
                type="button"
                title="Next"
                data-list-pagination="next"
              >
                <span class="fas fa-chevron-right"> </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterComp />

      <!-- Start of order preview modal  -->
      <div
        v-for="(order, i) in resources"
        :key="i"
        class="modal fade"
        :id="'staticBackdrop' + order.ID"
        data-bs-keyboard="false"
        tabindex="-1"
        :aria-labelledby="'staticBackdrop' + order.ID + 'Label'"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Order Preview</h4>
                <p class="fs--2 mb-0">
                  Added by
                  <a class="link-600 fw-semi-bold" href="#!">{{
                    order.owner.FIRST_NAME
                  }}</a>
                </p>
              </div>
              <div class="p-4">
                <div class="card mb-3">
                  <div
                    class="bg-holder d-none d-lg-block bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-4.png');
                      opacity: 0.7;
                    "
                  ></div>
                  <!--/.bg-holder-->
                  <div class="card-body position-relative">
                    <div class="row">
                      <div class="col-6">
                        <h5>Order Details: #{{ order.ID }}</h5>
                        <p class="fs--1">
                          {{ formatDate(order.CREATED_AT) }}
                        </p>
                        <div>
                          <strong class="me-2">Status: </strong>
                          <div
                            class="badge rounded-pill badge-soft-success fs--2"
                          >
                            {{ order.STATUS }}
                            <span
                              class="fas fa-check ms-1"
                              data-fa-transform="shrink-2"
                            ></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <ul class="nav flex-lg-column fs--1">
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              class="nav-link nav-link-card-details bg-success text-white"
                              @click="payReceiptMpesa(order)"
                              role="button"
                              ><span class="fas fa-user me-2"></span>Receive
                              MPESA</a
                            >
                          </li>
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              class="nav-link nav-link-card-details bg-primary text-white"
                              @click="payReceipt(order)"
                              role="button"
                              ><span class="fas fa-tag me-2"></span>Receive
                              CASH</a
                            >
                          </li>
                        </ul>
                      </div>
                      <div class="col-3">
                        <ul class="nav flex-lg-column fs--1">
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              class="nav-link nav-link-card-details bg-info text-white"
                              @click="printReceipt(order)"
                              role="button"
                              ><span class="fas fa-print me-2"></span>Print
                              Receipt</a
                            >
                          </li>
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              class="nav-link nav-link-card-details bg-danger text-white"
                              @click="closeTicket(order)"
                              role="button"
                              ><span class="fa fa-align-left me-2"></span>Close
                              Ticket
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <h5 class="mb-3 fs-0">Billing Address</h5>
                        <h6 class="mb-2">
                          {{ order.customer.FIRST_NAME }}
                          {{ order.customer.LAST_NAME }}
                        </h6>
                        <p class="mb-1 fs--1">
                          {{ order.customer.COUNTRY }},
                          {{ order.customer.CITY }}
                        </p>
                        <p class="mb-0 fs--1">
                          <strong>Email: </strong
                          ><a href="mailto:ricky@gmail.com">{{
                            order.customer.EMAIL
                          }}</a>
                        </p>
                        <p class="mb-0 fs--1">
                          <strong>Phone: </strong
                          ><a :href="`tel:${order.customer.MOBILE_NO}`">{{
                            order.customer.MOBILE_NO
                          }}</a>
                        </p>
                      </div>
                      <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <h5 class="mb-3 fs-0">Shipping Address</h5>
                        <h6 class="mb-2">
                          {{ order.customer.FIRST_NAME }}
                          {{ order.customer.LAST_NAME }}
                        </h6>
                        <p class="mb-0 fs--1">
                          {{
                            order.DELIVERY_ADDRESS
                              ? order.DELIVERY_ADDRESS
                              : "On site"
                          }}
                          <br />
                          {{
                            order.CUSTOMER_PICKEUP
                              ? order.CUSTOMER_PICKEUP
                              : "Walk in customer"
                          }}
                        </p>
                        <div class="text-500 fs--1">
                          {{
                            order.DELIVERY_CHARGE
                              ? order.DELIVERY_CHARGE
                              : "(Free Shipping)"
                          }}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <h5 class="mb-3 fs-0">Payment Method</h5>
                        <div class="d-flex">
                          <img
                            class="me-3"
                            src="../assets/img/icons/mpesa.png"
                            width="40"
                            height="30"
                            alt=""
                          />
                          <div class="flex-1">
                            <h6 class="mb-0">
                              {{ order.customer.FIRST_NAME }}
                              {{ order.customer.LAST_NAME }}
                            </h6>
                            <p class="mb-0 fs--1">
                              {{
                                order.PAYMENT_METHOD
                                  ? order.PAYMENT_METHOD
                                  : "(N/A)"
                              }}
                            </p>
                          </div>
                        </div>
                        <ul class="nav flex-lg-column fs--1 mt-3">
                          <li class="nav-item me-2 me-lg-0">
                            <a class="nav-link nav-link-card-details" href="#!"
                              ><span class="fas fa-user me-2"></span>Change
                              Customer</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="table-responsive fs--1">
                      <table class="table table-striped border-bottom">
                        <thead class="bg-200 text-900">
                          <tr>
                            <th class="border-0">Products</th>
                            <th class="border-0 text-center">Quantity</th>
                            <th class="border-0 text-end">Rate</th>
                            <th class="border-0 text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="border-200"
                            v-for="(prod, i) in order.items"
                            :key="i"
                          >
                            <td class="align-middle">
                              <h6 class="mb-0 text-nowrap">
                                {{ prod.ITEM_NAME }}
                              </h6>
                            </td>
                            <td class="align-middle text-center">
                              {{ prod.ITEM_QUANTITY }}
                            </td>
                            <td class="align-middle text-end">
                              {{ formatToCurrency(prod.ITEM_PRICE) }}
                            </td>
                            <td class="align-middle text-end">
                              {{ formatToCurrency(prod.TOTAL_PRICE) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="row g-0 justify-content-end">
                      <div class="col-auto">
                        <table
                          class="table table-sm table-borderless fs--1 text-end"
                        >
                          <tr>
                            <th class="text-900">Subtotal:</th>
                            <td class="fw-semi-bold">
                              {{
                                order.DELIVERY_CHARGE
                                  ? formatToCurrency(
                                      order.TOTAL_PRICE - order.DELIVERY_CHARGE
                                    )
                                  : formatToCurrency(order.TOTAL_PRICE)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th class="text-900">Shipping</th>
                            <td class="fw-semi-bold">
                              {{
                                order.DELIVERY_CHARGE
                                  ? formatToCurrency(order.DELIVERY_CHARGE)
                                  : "0.00"
                              }}
                            </td>
                          </tr>
                          <tr class="">
                            <th class="text-900">Paid:</th>
                            <td class="fw-semi-bold">
                              {{ formatToCurrency(order.PAID_AMOUNT) }}
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <th class="text-900">Due/Bal:</th>
                            <td class="fw-semi-bold">
                              {{ formatToCurrency(order.DUE_AMOUNT) }}
                            </td>
                          </tr>
                          <tr class="border-top">
                            <th class="text-900">Total:</th>
                            <td class="fw-semi-bold">
                              {{ formatToCurrency(order.TOTAL_PRICE) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of order preview modal  -->

      <!-- Start of confirm merge modal  -->
      <div
        class="modal fade"
        id="mergeModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          role="document"
          style="max-width: 500px"
        >
          <div class="modal-content position-relative">
            <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                <h4 class="mb-1">Confirm Merge</h4>
              </div>
              <div class="px-5 pb-0">
                <p class="text-center">
                  Do you really want to merge the following tickets?
                </p>

                <button
                  v-for="(element, i) in selected"
                  :key="i"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Click to remove"
                  @click="removeItem(element)"
                  class="btn btn-outline-warning me-1 mb-1"
                  type="button"
                >
                  Ticket # {{ element }}
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                type="button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="btn btn-primary"
                type="button"
                data-bs-dismiss="modal"
                @click="mergeConfirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End of confirm merge modal  -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderService from "../services/orders.service";
import OrdersService from "../services/orders.service";
import CustomerService from "../services/customer.service";
import TicketService from "../services/ticket.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";

const now = new Date();

export default {
  name: "TicketAnalysisReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
  },
  data() {
    return {
      editedItem: {},
      selected: [],
      selected_items: "",
      sort_order: 0,
      orders_table_key: 0,
      enable_download: false,
      preview_modal: false,
      printarea_key: 1,
      resources: [],
      customers: [],
      formData: {
        from: moment(new Date()).format("DD-MM-YYYY"),
        to: moment(new Date()).format("DD-MM-YYYY"),
      },
      config: {
        dateFormat: "M j, Y",
      },
    };
  },
  created() {},
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.getOpenOrders();
    this.getCustomers();
    // this.getAllOrders();
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("Loading..");
    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  methods: {
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    removeItem(item) {
      this.selected = this.selected.filter((el) => el != item);
      console.log(this.selected);
    },
    mergeConfirm() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        ticket_ids: this.selected_items,
      };
      TicketService.mergeTicket(formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success("Tickets merged successfully!", {
              autoClose: 3000,
            });
            this.getOpenOrders();
            this.selected = [];
            this.selected_items = "";
            this.$store.commit("SET_LOADING", false);
          } else {
            toast.error("Error merging tickets!", {
              autoClose: 3000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    sortBy(field_name) {
      console.log("Sorting...", field_name);
      switch (field_name) {
        case "amount":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order
              ? a.TOTAL_PRICE - b.TOTAL_PRICE
              : b.TOTAL_PRICE - a.TOTAL_PRICE
          );
          break;
        case "date":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order
              ? new Date(a.CREATE_DATE).getTime() -
                new Date(b.CREATE_DATE).getTime()
              : new Date(b.CREATE_DATE).getTime() -
                new Date(a.CREATE_DATE).getTime()
          );
          console.log("here sorting");
          break;

        default:
          break;
      }
      this.sort_order = !this.sort_order;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    voidTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.ID;
      let proceed = confirm(
        "Are you sure you want to void? This action cannot be undone!"
      );
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.voidTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.preview_receipt_modal = false;
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket voided!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error voiding ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    giftTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.ID;
      let proceed = confirm(
        "Are you sure you want to gift? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.giftTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket gifted!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error gifting Ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    closeTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.ID;
      let proceed = confirm(
        "Are you sure you want to close? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.closeTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket closed!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error closing ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    displayStatus(status) {
      let badge = "";
      switch (status) {
        case "OPEN":
          badge = "success lighten-1 text-capitalize success--text";
          break;
        default:
          badge = "secondary lighten-1 text-capitalize  secondary--text";
          break;
      }
      return badge;
    },
    onProgress(e) {
      console.log(e);
    },
    hasStartedGeneration() {},
    hasGenerated(e) {
      console.log(e);
    },
    payReceipt(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.editedItem.amount = amount;
        this.editedItem.mode = "CASH";
        this.editedItem.ticket_id = this.editedItem.ID;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
            this.$router.go('/')
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    payReceiptMpesa(selected_item) {
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.$store.commit("SET_LOADING", true);
        this.editedItem.amount = amount;
        this.editedItem.mode = "MPESA";
        this.editedItem.ticket_id = this.editedItem.ID;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              this.$store.commit("SET_LOADING", false);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
            }
            this.$router.go('/')
          },
          (error) => {
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    changeCustomer(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      this.editedItem.customer_id = this.selected_customer;
      this.editedItem.ticket_id = this.editedItem.ID;
      TicketService.change(this.editedItem).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Ticket updated!", {
              autoClose: 2000,
            });
          } else {
            console.log(response.data.error);
            toast.error("Error updating ticket!", {
              autoClose: 2000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    statusChanged() {
      let status = this.order_status;
      switch (status) {
        case 0:
          // this.getAllOrders(); deactivated for datas
          this.getOpenOrders();
          break;
        case 1:
          this.getOpenOrders();
          break;
        case 2:
          this.getClosedOrders();
          break;
        case 3:
          this.getVoidedOrders();
          break;
        default:
          this.getAllOrders();
          break;
      }
    },

    printReceipt(selected_item) {
      console.log(selected_item)
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.NAME;
      this.formData.date = this.editedItem.CREATED_AT;
      this.formData.reference = "BL" + this.editedItem.ID;
      this.formData.created_by = this.editedItem.owner.FIRST_NAME;
      this.formData.address_line_1 = this.user.company.ADDRESS_LINE1;
      this.formData.address_line_2 = this.user.company.ADDRESS_LINE2;
      this.formData.address_line_3 = this.user.company.ADDRESS_LINE3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.SUB_TOTAL;
      this.formData.paid = this.editedItem.PAID_AMOUNT;
      this.formData.due = this.editedItem.DUE_AMOUNT;
      this.formData.tax = this.editedItem.TOTAL_TAX;
      this.formData.total_amount = this.editedItem.TOTAL_PRICE;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printReceipt(this.formData).then(
        (response) => {
          toast.success("Receipt printed!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    printBill(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.NAME;
      this.formData.date = this.editedItem.CREATED_AT;
      this.formData.reference = "BL" + this.editedItem.ID;
      this.formData.created_by = this.editedItem.owner.FIRST_NAME;
      this.formData.address_line_1 = this.user.company.ADDRESS_LINE1;
      this.formData.address_line_2 = this.user.company.ADDRESS_LINE2;
      this.formData.address_line_3 = this.user.company.ADDRESS_LINE3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.SUB_TOTAL;
      this.formData.paid = this.editedItem.PAID_AMOUNT;
      this.formData.due = this.editedItem.DUE_AMOUNT;
      this.formData.tax = this.editedItem.TOTAL_TAX;
      this.formData.total_amount = this.editedItem.TOTAL_PRICE;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printBill(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    printOrder(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.NAME;
      this.formData.date = this.editedItem.CREATED_AT;
      this.formData.reference = "BL" + this.editedItem.ID;
      this.formData.created_by = this.editedItem.owner.FIRST_NAME;
      this.formData.address_line_1 = this.user.company.ADDRESS_LINE1;
      this.formData.address_line_2 = this.user.company.ADDRESS_LINE2;
      this.formData.address_line_3 = this.user.company.ADDRESS_LINE3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.SUB_TOTAL;
      this.formData.tax = this.editedItem.TOTAL_TAX;
      this.formData.total_amount = this.editedItem.TOTAL_PRICE;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printOrder(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    generateReport(name) {
      this.report_name = name;
      setTimeout(() => {
        var printable_content = document.getElementById("printable_content");
        var pdf = document.getElementById("printarea");
        pdf.innerHTML = printable_content.innerHTML;
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCustomers() {
      return CustomerService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.customers = response.data.data;
          } else {
            this.customers = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getAllOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.index().then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getClosedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.closed().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getVoidedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.voided().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.resources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    previewReceiptModal(order) {
      this.editedItem = Object.assign({}, order);
      this.editedIndex = this.resources.indexOf(order);
      this.cart = Object.assign({}, order.order);
      this.totalValue = order.TOTAL_PRICE;
      this.editedItem.ticket_id = order.ID;
    },
    changeCustomerModal() {
      this.change_customer_modal = true;
    },
    viewItem(item) {
      this.editedIndex = this.resources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
