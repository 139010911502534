// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class CustomerService {
    index() {
        return axios.get(VUE_APP_BASE_URL + 'customer', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'customer', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'customer/' + resource.id, resource, { headers: authHeader() })
    }
    delete(id) {
        return axios.delete(VUE_APP_BASE_URL + 'customer/' + id, { headers: authHeader() })
    }
}

export default new CustomerService()