<template>
  <div id="app">
    <LoaderOverlay v-if="loading==false" />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import LoaderOverlay from "@/components/LoaderOverlay.vue";

const defaultLayout = "default";

export default {
  components: {
    LoaderOverlay,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>
