<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-sm-auto d-flex align-items-center">
              <img
                class="ms-n2"
                src="../assets/img/illustrations/crm-bar-chart.png"
                alt=""
                width="90"
              />
              <div class="">
                <h5 class="text-primary fs--1 mb-0 mt-2">
                  {{ getTimegreetings() }}, {{ user.first_name }},
                </h5>
                <!-- <h6 class="text-primary fs--1 mb-0">Welcome to</h6> -->
                <div class="d-flex">
                  <span class="text-600 fs--1 d-flex pt-2"> Welcome to </span>
                  <span class="text-primary fw-bold mb-0 pt-1 ms-2">
                    Bulla <span class="text-info fw-medium">BIS</span>
                  </span>
                </div>
              </div>
              <img
                class="ms-n4 d-md-none d-lg-block"
                src="../assets/img/illustrations/crm-line-chart.png"
                alt=""
                width="150"
              />
            </div>
            <div class="col-md-auto p-3">
              <form class="row align-items-center g-2">
                <div class="col-auto">
                  <h6 class="text-700 mb-0">Showing Data For:</h6>
                </div>
                <div class="col-md-auto position-relative">
                  <flat-pickr
                    v-model="date"
                    @change="dateChanged"
                    id="CRMDateRange"
                    class="form-control form-control-sm datetimepicker ps-4"
                    style="max-width: 130px"
                    placeholder="Select Start"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-2 mb-2">
      <div class="col-12 col-xl-12">
        <div class="row g-3">
          <div class="col-lg-12">
            <div class="row g-3 mb-2">
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                    "
                  ></div>
                  <div
                    class="card-body position-relative"
                    :key="daily_analysis.customers"
                  >
                    <h6>
                      Quotation<span
                        class="badge badge-soft-warning rounded-pill ms-2"
                        >All</span
                      >
                    </h6>
                    <count-up
                      class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning"
                      :end-val="daily_analysis.customers"
                      :start-val="0"
                    ></count-up>
                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      @click="goToCustomers"
                      >See all<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="card overflow-hidden" style="min-width: 12rem">
                  <div
                    class="bg-holder bg-card"
                    style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                    "
                  ></div>
                  <!--/.bg-holder-->
                  <div class="card-body position-relative">
                    <h6>
                      Paid Invoices<span
                        class="badge badge-soft-info rounded-pill ms-2"
                        >{{
                          daily_analysis.total_orders > 0
                            ? (daily_analysis.closed_orders /
                                daily_analysis.total_orders) *
                              100
                            : 0
                        }}%</span
                      >
                    </h6>
                    <count-up
                      class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                      :end-val="daily_analysis.closed_orders"
                      :start-val="0"
                    ></count-up>

                    <a
                      class="fw-semi-bold fs--1 text-nowrap"
                      href="../app/e-commerce/orders/order-list.html"
                      >All orders<span
                        class="fas fa-angle-right ms-1"
                        data-fa-transform="down-1"
                      ></span
                    ></a>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card h-md-100 h-100">
                  <div class="card-body">
                    <div class="row h-100 justify-content-between g-0">
                      <div class="col-5 col-sm-6 col-xxl pe-2">
                        <h6 class="mt-1">Market Share</h6>
                        <div class="fs--2 mt-3" v-if="weekly_analysis">
                          <div
                            v-for="(
                              value, name, index
                            ) in weekly_analysis.owners"
                            :key="i"
                            class="d-flex flex-between-center mb-1"
                          >
                            <div class="d-flex align-items-center">
                              <span :class="getRandomColors()"></span
                              ><span class="fw-semi-bold">{{ name }}</span>
                            </div>
                            <div class="d-xxl-none">
                              {{ value.length }} Tickets
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card h-md-100 ecommerce-card-min-width">
                  <div class="card-header pb-0">
                    <h6 class="mb-0 mt-2 d-flex align-items-center">
                      Weekly Sales<span
                        class="ms-1 text-400"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Calculated according to last week's sales"
                        ><span
                          class="far fa-question-circle"
                          data-fa-transform="shrink-1"
                        ></span
                      ></span>
                    </h6>
                  </div>
                  <div class="card-body d-flex flex-column justify-content-end">
                    <div class="row">
                      <div class="col">
                        <count-up
                          class="display-4 fs-3 mb-2 fw-normal font-sans-serif text-success"
                          :end-val="weekly_analysis.sales"
                          :start-val="0"
                        >
                          <template #prefix>
                            <span style="me-2">KES</span>
                          </template>
                        </count-up>
                      </div>
                      <div class="col-auto ps-0">
                        <div
                          class="echart-bar-weekly-sales h-100 echart-bar-weekly-sales-smaller-width"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body py-3">
            <h6 class="px-1 text-700 me-2 mb-2">Quick Links</h6>
            <span class="ms-auto">
              <button
                class="btn btn-sm btn-outline-info me-1 py-2"
                @click="goToApplication"
              >
                New Quote <i class="fa fa-file-code"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-danger me-2 py-2"
                @click="goToPremiums"
              >
                Edit Premiums
                <span class="badge rounded-pill ms-1 badge-soft-info">New</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-warning me-2 py-2"
                @click="goToUsers"
              >
                Users
                <span class="badge rounded-pill ms-1 badge-soft-info">All</span>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-primary me-2 py-2"
                @click="goToCustomers"
              >
                Customers
                <span class="badge rounded-pill ms-1 badge-soft-success"
                  >20</span
                >
              </button>
              <button
                type="button"
                class="btn btn-sm btn-outline-primary me-2 py-2"
                @click="goToHistory"
              >
                Application History
                <span class="badge rounded-pill ms-1 badge-soft-info"
                  >monthly</span
                >
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Histories  -->

    <div class="row mt-3">
      <!-- Quotation  -->
      <div class="col-6">
        <!-- Quotations Downloads -->
        <div class="card mb-3" id="ordersTable">
          <div class="card-header">
            <div class="row flex-between-center border-bottom pb-1">
              <div class="col-4 col-sm-auto align-items-center pe-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  <i class="fa fa-building text-warning"></i>
                  Quotation History
                </h5>
                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                  Quotations Generated & Shared
                </p>
              </div>
              <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                <div id="orders-actions" class="d-flex"></div>
              </div>
            </div>
          </div>
          <div class="card-body px-3 py-0">
            <!-- Menu items table  -->
            <div class="table-responsive scrollbar">
              <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped fs--1 mb-0">
                  <thead class="bg-200 text-900">
                    <tr>
                      <!-- <th class="sort" data-sort="name">Item No.</th> -->
                      <th class="sort" data-sort="age">Name</th>
                      <th class="sort" data-sort="age">Served by</th>
                      <th class="sort" data-sort="age">Status</th>
                      <th class="sort" data-sort="age">Amount</th>
                      <th class="sort" data-sort="email">Date</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr v-for="(item, i) in resources" :key="item.ID">
                      <!-- <td>{{ item.id }}</td> -->
                      <td>
                        <a
                          :href="`${base_url}history-download/${item.name}`"
                          _target="blank"
                        >
                          {{ item.name }}</a
                        >
                      </td>
                      <td>{{ item.created_by }}</td>
                      <td>
                        <span
                          v-if="item.status == 0"
                          class="badge badge rounded-pill d-block badge-soft-secondary"
                          >Draft
                          <span
                            class="ms-1 fas fa-ban"
                            data-fa-transform="shrink-2"
                          >
                          </span>
                        </span>
                        <span
                          v-if="item.status == 1"
                          class="badge badge rounded-pill d-block badge-soft-danger"
                        >
                          Completed
                          <span
                            class="ms-1 fas fa-stream"
                            data-fa-transform="shrink-2"
                          >
                          </span>
                        </span>
                      </td>
                      <td>{{ item.total }}</td>
                      <td>{{ formatDate(item.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <pagination
                  v-model="current_page"
                  :records="records"
                  :per-page="per_page"
                  @paginate="myCallback"
                />
              </div>
            </div>
            <!-- End Menu items table  -->
          </div>
          <div class="card-footer">
            <!-- footer content  -->
          </div>
        </div>
      </div>
      <!-- Summary  -->
      <div class="col-6">
        <!-- Summary Downloads  -->
        <div
          class="card mb-3"
          id="ordersTable"
          data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
        >
          <div class="card-header">
            <div class="row flex-between-center border-bottom pb-1">
              <div class="col-4 col-sm-auto align-items-center pe-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  <i class="fa fa-building text-warning"></i>
                  Summary History
                </h5>
                <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                  Summary Generated & Shared
                </p>
              </div>
              <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                <div id="orders-actions" class="d-flex"></div>
              </div>
            </div>
          </div>
          <div class="card-body px-3 py-0">
            <!-- Menu items table  -->
            <div class="table-responsive scrollbar">
              <div class="table-responsive scrollbar">
                <table class="table table-bordered table-striped fs--1 mb-0">
                  <thead class="bg-200 text-900">
                    <tr>
                      <!-- <th class="sort" data-sort="name">Item No.</th> -->
                      <th class="sort" data-sort="age">Name</th>
                      <th class="sort" data-sort="age">Served by</th>
                      <th class="sort" data-sort="email">Date</th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr v-for="(item, i) in resources_s" :key="item.ID">
                      <!-- <td>{{ item.id }}</td> -->
                      <td>
                        <a
                          :href="`${base_url}history-download/${item.name}`"
                          _target="blank"
                        >
                          {{ item.name }}</a
                        >
                      </td>
                      <td>{{ item.created_by }}</td>
                      <td>{{ formatDate(item.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-flex justify-content-center mt-3">
                <pagination
                  v-model="current_page_s"
                  :records="records_s"
                  :per-page="per_page_s"
                  @paginate="myCallbackSummary"
                />
              </div>
            </div>
            <!-- End Menu items table  -->
          </div>
          <div class="card-footer">
            <!-- footer content  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ProviderService from "../services/provider.service";
import EasyDataTable from "vue3-easy-data-table";
import SimpleTypeahead from "vue3-simple-typeahead";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css"; //Optional default CSS
import Pagination from "v-pagination-3";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    flatPickr,
    Pagination,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: new Date(),
      records: 0,
      per_page: 25,
      current_page: 0,
      records_s: 0,
      per_page_s: 25,
      current_page_s: 0,
      iframe_route: "",
      iframe_key: "",
      refreshed: 0,
      providers: [],
      daily_analysis: [],
      weekly_analysis: [],
      headers: [
        { text: "Provider", value: "name", sortable: true },
        { text: "Type", value: "type", sortable: true },
        {
          text: "Total (Ksh)",
          value: "amount",
          sortable: true,
          width: 120,
        },
      ],
      formData: {
        car_value: "",
        insurance_type: "MPC",
      },
      year: new Date().getFullYear(),
      last_15_years: this.generateLastYears(15),
      car_makes: [
        "Abarth",
        "Acura",
        "Alfa",
        "Aston",
        "Audi",
        "Bentley",
        "BMW",
        "Buick",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Dacia",
        "Ferrari",
        "Fiat",
        "Ford",
        "GMC",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Lancia",
        "Land",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Mazda",
        "Mercedes",
        "Mercury",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Pontiac",
        "Porsche",
        "Ram",
        "Renault",
        "Saab",
        "Saturn",
        "Scion",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Wiesmann",
      ],
    };
  },
  validations() {
    return {
      formData: {
        customer_name: { required },
        car_value: { required },
        courtesy_car: { required },
        excess_protector: { required },
        pvt: { required },
        yom: { required },
        reg_no: { required },
        phone: { required, minLength: minLength(8) },
        email: { required, email },
      },
    };
  },
  created() {
    // this.forceReload();
    this.getWeeklyAnalysis();
    this.getDailyAnalysis();
    this.getData();
    this.getDataSummary();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    getData() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.history().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataSummary() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historySummary().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources_s = response.data.data.data;
            this.records_s = response.data.data.total;
            this.current_page_s = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataPage(page) {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historyPage(page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataPageSummary(page) {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historyPageSummary(page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    goToSample() {
      this.$router.push("/quotation-sample");
    },
    goToApplication() {
      this.$router.push("/new-application");
    },
    goToPremiums() {
      this.$router.push("/premium-providers");
    },
    goToUsers() {
      this.$router.push("/user.index");
    },
    goToCustomers() {
      this.$router.push("/customer.index");
    },
    goToHistory() {
      this.$router.push("/application-history");
    },
    phoneUpdated(e) {
      this.v$.formData.phone.$touch();
      console.log(e.target.value);
      this.formData.phone = e.target.value;
    },
    customer_nameUpdated(e) {
      this.v$.formData.customer_name.$touch();
    },
    reg_noUpdated() {
      this.v$.formData.reg_no.$touch();
    },
    car_valueUpdated() {
      this.v$.formData.car_value.$touch();
    },
    emailUpdated() {
      this.v$.formData.email.$touch();
    },
    insurance_typeUpdated() {
      this.v$.formData.insurance_type.$touch();
    },
    makeUpdated(make) {
      console.log(make);
      this.formData.car_make = make;
      this.v$.formData.car_make.$touch();
    },
    async showRow(item) {
      let data = {
        id: item.id,
        form_data: this.formData,
        premium_provider: item,
      };
      console.log("item", item);
      console.log(data);
      const encoded = await window.btoa(JSON.stringify(data));
      this.iframe_route =
        process.env.VUE_APP_ROOT_URL + "#/quotation/" + encoded;
      this.iframe_key = encoded;
      var myModal = new bootstrap.Modal(
        document.getElementById("quotationpreviewModal")
      );
      myModal.show();

      // $("#quotationpreviewModal").show();
      // window.open(route.href);
    },
    yearSelected(e) {
      this.formData.yom = e.target.value;
      this.getQuotations();
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },

    getQuotations() {
      this.v$.formData.car_value.$touch();
      this.v$.formData.yom.$touch();
      this.v$.formData.reg_no.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.email.$touch();
      console.group("Valid");
      if (this.formData.car_value > 5000000) {
        toast.error("Please gnerate a custom quote for values above 5M!", {
          autoClose: 6000,
        });
      }
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      return ProviderService.quote(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.providers = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
          toast.success("Horaay! Quotation generated successfully!");
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          toast.error("Snap! A server error occurred!");
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
