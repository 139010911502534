<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <div class="card bg-100 shadow-none border">
          <div class="row gx-0 flex-between-center">
            <div class="col-12 col-sm-12 col-lg-6 d-flex align-items-center">
              <img
                class="ms-n2"
                src="../assets/img/illustrations/crm-bar-chart.png"
                alt=""
                width="90"
              />
              <div class="d-none d-md-block">
                <h5 class="text-primary fs--1 mb-0 mt-2">
                  {{ getTimegreetings() }}, {{ user.first_name }}!
                </h5>
                <!-- <h6 class="text-primary fs--1 mb-0">Welcome to</h6> -->
                <div class="d-flex">
                  <span class="text-600 fs--1 d-flex pt-2"> Welcome to </span>
                  <span class="text-primary fw-bold mb-0 pt-1 ms-2">
                    Policy <span class="text-info fw-medium">Pal</span>
                  </span>
                </div>
              </div>
              <div class="d-block d-md-none">
                <span class="text-primary fs--1 mb-0 mt-2">
                  {{ getTimegreetings() }},
                  <strong> {{ user.first_name }}!</strong>
                </span>
              </div>
              <img
                class="ms-n4 d-md-none d-lg-block"
                src="../assets/img/illustrations/crm-line-chart.png"
                alt=""
                width="150"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-xl-12">
        <div class="card py-3 mb-3">
          <div class="card-body py-3">
            <div class="row g-0">
              <!-- Start of processor card  -->
              <div
                class="col-12 col-md-4 border-200 border-bottom border-end pb-4"
              >
                <h6 class="px-1 text-700 me-2 mb-3">Application Details</h6>
                <div id="tour-step-0" class="row mb-3 pe-2">
                  <div class="col-12">
                    <input
                      class="form-control"
                      id="name"
                      name="name"
                      type="text"
                      v-model="formData.customer_name"
                      @change="customerNameUpdated"
                      placeholder="Client's Full Name *"
                      :class="
                        v$.formData.customer_name.$dirty &&
                        v$.formData.customer_name.$invalid
                          ? `is-invalid`
                          : v$.formData.customer_name.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.customer_name.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Customer name is required.
                    </div>
                  </div>
                </div>

                <div class="row mb-3 pe-2">
                  <div class="col-12">
                    <vue-tel-input
                      id="phone"
                      name="phone"
                      @change="phoneUpdated"
                      placeholder="Phone Number"
                      :class="
                        v$.formData.phone.$dirty && v$.formData.phone.$invalid
                          ? `is-invalid`
                          : v$.formData.phone.$dirty
                          ? `is-valid`
                          : ``
                      "
                    ></vue-tel-input>
                    <div
                      v-for="error of v$.formData.phone.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Customer phone is required.
                    </div>
                  </div>
                </div>
                <div class="row mb-3 pe-2">
                  <div class="col-12">
                    <input
                      class="form-control"
                      type="email"
                      id="email"
                      name="email"
                      v-model="formData.email"
                      @change="emailUpdated"
                      placeholder="Email *"
                      :class="
                        v$.formData.email.$dirty && v$.formData.email.$invalid
                          ? `is-invalid`
                          : v$.formData.email.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.email.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Customer email is invalid.
                    </div>
                  </div>
                </div>
                <div class="row mb-3 pe-2">
                  <div class="col-6">
                    <SimpleTypeahead
                      class="form-select"
                      id="car-make"
                      name="car-make"
                      placeholder="Car Make "
                      :items="car_makes"
                      :minInputLength="1"
                      :itemProjection="itemProjectionFunction"
                      @selectItem="makeSelected"
                      @onInput="makeUpdated"
                      @onFocus="onFocusEventHandler"
                      @onBlur="onBlurEventHandler"
                      :class="
                        v$.formData.car_make.$dirty &&
                        v$.formData.car_make.$invalid
                          ? `is-invalid`
                          : v$.formData.car_make.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                    </SimpleTypeahead>
                    <div
                      v-for="error of v$.formData.car_make.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Car make is invalid.
                    </div>
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      id="inputName"
                      type="text"
                      v-model="formData.car_model"
                      @change="customerNameUpdated"
                      placeholder="Model e.g Vitz*"
                      :class="
                        v$.formData.car_model.$dirty &&
                        v$.formData.car_model.$invalid
                          ? `is-invalid`
                          : v$.formData.car_model.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.car_model.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Model is invalid.
                    </div>
                  </div>
                </div>
                <div class="row mb-3 pe-2">
                  <div class="col-6">
                    <input
                      class="form-control"
                      id="inputReg"
                      placeholder="Registration No*"
                      v-model="formData.reg_no"
                      @change="regNoUpdated"
                      :class="
                        v$.formData.reg_no.$dirty && v$.formData.reg_no.$invalid
                          ? `is-invalid`
                          : v$.formData.reg_no.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.reg_no.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Registration number is required.
                    </div>
                  </div>
                  <div class="col-6">
                    <select
                      class="form-select"
                      id="inlineFormSelectPref"
                      placeholder="YOM e.g 2013*"
                      @change="yearSelected"
                      :class="
                        v$.formData.yom.$dirty && v$.formData.yom.$invalid
                          ? `is-invalid`
                          : v$.formData.yom.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option selected="0">YOM</option>
                      <option v-for="item in last_15_years" :value="item">
                        {{ item }}
                      </option>
                      <option value="1">
                        Ealier than {{ last_15_years.at(-1) }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.yom.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! Year of Manufacture is required.
                    </div>
                  </div>
                </div>
                <div class="row mb-3 pe-2">
                  <div class="col-12">
                    <input
                      id="inputValue"
                      v-model="formData.car_value"
                      @change="getQuotations"
                      class="form-control"
                      :class="
                        v$.formData.car_value.$dirty &&
                        v$.formData.car_value.$invalid
                          ? `is-invalid`
                          : v$.formData.car_value.$dirty
                          ? `is-valid`
                          : ``
                      "
                      type="number"
                      placeholder="Estimated Value (Ksh) *"
                    />
                    <div
                      v-for="error of v$.formData.car_value.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! The estimate car value is required.
                    </div>
                  </div>
                </div>
                <div class="row mb-3 pe-2">
                  <div class="col-12">
                    <select
                      class="form-select"
                      id="inlineFormSelectPref"
                      placeholder="Type of Insurance**"
                      v-model="formData.insurance_type"
                      @change="insurance_typeUpdated"
                      disabled
                    >
                      <option value="MPC">Motor Private Comprehensive</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3 pe-2 pt-2">
                  <div class="col-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        id="flexSwitchCheckDefault"
                        type="checkbox"
                        v-model="formData.excess_protector"
                        @change="getQuotations"
                      /><label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        >Excess Protector</label
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        id="flexSwitchCheckDefault"
                        type="checkbox"
                        v-model="formData.pvt"
                        @change="getQuotations"
                      /><label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        >PVT</label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        id="flexSwitchCheckDefault"
                        type="checkbox"
                        v-model="formData.courtesy_car"
                        @change="getQuotations"
                      /><label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                        >Courtesy Car</label
                      >
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-3 border-top">
                  <!-- <button
                      class="btn w-32 btn-falcon-default btn-sm me-1 mb-1"
                      @click="resetForm"
                      type="button"
                    >
                      <span
                        class="fa fa-refresh me-1"
                        data-fa-transform="shrink-3"
                      ></span
                      >Reset
                    </button> -->
                  <!-- <button
                      @click="saveCustomer"
                      class="
                        btn
                        w-32
                        btn-falcon-warning btn-sm
                        me-1
                        mb-1
                        ms-auto
                      "
                      type="button"
                    >
                      <span
                        class="fa fa-user-plus me-1"
                        data-fa-transform="shrink-3"
                      ></span
                      >Save
                    </button> -->
                  <button
                    @click="getQuotations"
                    class="btn w-32 btn-falcon-warning btn-sm me-1 mb-1 ms-auto"
                    type="button"
                  >
                    <span
                      class="fa fa-check-circle me-1"
                      data-fa-transform="shrink-3"
                    ></span
                    >Generate
                  </button>
                </div>
              </div>
              <div
                class="
                  col-12 col-md-8
                  border-200 border-md-200 border-bottom border-md-end
                "
              >
                <div class="mx-1">
                  <div class="d-flex">
                    <h6 class="px-1 text-700 me-2 mb-2">Summary of Premiums</h6>
                    <span class="ms-auto mt-n2">
                      <button
                        class="
                          btn btn-sm btn-falcon-warning
                          w-auto
                          ms-auto
                          me-2
                          rounded-circle
                          px-2
                        "
                        :disabled="download_url.length == 0"
                      >
                        <a class="text-warning" :href="download_url" target="_blank">
                          <i class="fa fa-download"></i>
                        </a>
                      </button>
                      <button
                        :disabled="download_url.length == 0"
                        type="button"
                        class="
                          btn btn-sm
                          w-auto
                          btn-falcon-warning
                          ms-auto
                          me-2
                          rounded-circle
                          px-2
                        "
                        @click="shareSummary"
                        target="_blank"
                      >
                        <i class="fa fa-envelope text-warning"></i>
                      </button>
                    </span>
                  </div>
                  <div class="scrollbar list" style="max-height: 570px">
                    <EasyDataTable
                      id="tour-step-1"
                      class="my-2"
                      table-class-name="customize-table d-block d-md-none"
                      alternating
                      buttons-pagination
                      :headers="headers_mob"
                      :items="providers"
                      :search-field="searchField"
                      :search-value="searchValue"
                      :loading="loading"
                      @click-row="showRow"
                    >
                      <template #loading>
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </template>
                      <template #header-name="header">
                        <div class="bg-200 text-900">
                          <i class="fa fa-building" aria-hidden="true"></i>
                          {{ header.text }}
                        </div>
                      </template>
                      <template #header-amount="header">
                        <div class="bg-200 text-900 justify-content-right">
                          {{ header.text }}
                        </div>
                      </template>
                      <template #item-amount="item">
                        <span class="ms-auto" style="float: right !important"
                          >{{ formatToCurrency(Math.ceil(item.amount)) }}
                        </span>
                      </template>
                    </EasyDataTable>
                    <EasyDataTable
                      id="tour-step-1"
                      class="my-2 d-none d-md-block"
                      table-class-name="customize-table"
                      alternating
                      buttons-pagination
                      :headers="headers"
                      :items="providers"
                      :search-field="searchField"
                      :search-value="searchValue"
                      :loading="loading"
                      @click-row="showRow"
                    >
                      <template #loading>
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </template>
                      <template #header-name="header">
                        <div class="bg-200 text-900">
                          <i class="fa fa-building" aria-hidden="true"></i>
                          {{ header.text }}
                        </div>
                      </template>
                      <template #header-amount="header">
                        <div class="bg-200 text-900 justify-content-right">
                          {{ header.text }}
                        </div>
                      </template>

                      <template #item-name="item">
                        <span class="ms-auto" style="float: left !important">
                          {{ item.name }}
                        </span>
                      </template>

                      <template #item-amount="item">
                        <span class="ms-auto" style="float: left !important">
                          <img :src="item.image" width="32" class="me-2" />
                          {{ formatToCurrency(Math.ceil(item.amount)) }}
                        </span>
                      </template>
                    </EasyDataTable>
                  </div>
                </div>
              </div>
              <!-- End of processor card  -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Printable content  -->
    <div id="element-to-print" class="d-none">
      <div class="row">
        <div class="col-12 px-4">
          <div class="d-flex justify-content-center">
            <h2 class="mx-auto mt-4 position-absolute fw-bold">SUMMARY</h2>
          </div>
          <div class="row align-items-center text-center mb-3">
            <div class="col-sm-6 text-sm-start">
              <img src="assets/img/quotation_logo.png" alt="invoice" width="150" />
            </div>
            <div class="col text-sm-end mt-3 mt-sm-0">
              <h5 class="text-danger">JAZA INSURANCE AGENCY</h5>
              <p class="fs--1 mb-0">
                Western Heights, 6th Floor, Westlands, <br />
                P.O Box 1035-00200 Nairobi,<br />
                Tel. {{ user.phone }}/0709186333,<br />
                Email: {{ user.email }}
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <EasyDataTable
        id="tour-step-1"
        class="my-2"
        table-class-name="customize-table"
        alternating
        buttons-pagination
        :headers="headers"
        :items="providers"
        :search-field="searchField"
        :search-value="searchValue"
        :loading="loading"
        @click-row="showRow"
      >
        <template #loading>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </template>
        <template #header-name="header">
          <div class="bg-200 text-900">
            {{ header.text }}
          </div>
        </template>
        <template #item-amount="item">
          <span class="ms-auto" style="float: right !important">{{
            formatToCurrency(Math.ceil(item.amount))
          }}</span>
        </template>
        <template #item-name="item">
          <img :src="item.image" height="50px" />
          <span class="ms-auto" style="float: right !important">
            {{ item.name }}</span
          >
        </template>
      </EasyDataTable>
    </div>
    <!-- end printable  -->
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="
              modal-header
              px-5
              position-relative
              modal-shape-header
              bg-shape
            "
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="
                btn-close btn-close-white
                position-absolute
                top-0
                end-0
                mt-2
                me-2
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Quotation modal  -->
    <div
      class="modal fade"
      id="quotationpreviewModal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="false"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Quotation Preview</h4>
              <p class="fs--2 mb-0">
                Added by
                <a class="link-600 fw-semi-bold"
                  >{{ user.first_name || "" }} {{ user.last_name || "" }}</a
                >
              </p>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-lg-12">
                  <iframe
                    :key="iframe_key"
                    :src="iframe_route"
                    frameborder="0"
                    width="100%"
                    height="500px"
                    class="mt-n3"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-header-item-padding: 10px 15px;
}
</style>
<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import "vue3-toastify/dist/index.css";
import { toast } from "vue3-toastify";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import AuthService from "../services/auth.service";
import SimpleTypeahead from "vue3-simple-typeahead";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import CustomerService from "../services/customer.service";
import ProviderService from "../services/provider.service";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    flatPickr,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      date: new Date(),
      iframe_route: "",
      download_url: "",
      iframe_key: "",
      refreshed: 0,
      providers: [],
      daily_analysis: [],
      weekly_analysis: [],
      headers: [
        { text: "Provider", value: "name", sortable: true },
        { text: "Type", value: "type", sortable: true },
        {
          text: "Annual Premium (Ksh)",
          value: "amount",
          sortable: true,
          width: 200,
          align: "right",
        },
      ],
      headers_mob: [
        { text: "Provider", value: "name", sortable: true },
        {
          text: "Annual(KSH)",
          value: "amount",
          sortable: true,
          align: "left",
          width: 110,
        },
      ],
      formData: {
        car_value: "",
        insurance_type: "MPC",
        owner_id: JSON.parse(localStorage.getItem("user")).auto_id,
        owner_email:JSON.parse(localStorage.getItem("user")).email,
        owner_phone: JSON.parse(localStorage.getItem("user")).phone_no,
        owner_name: JSON.parse(localStorage.getItem("user")).first_name,
      },
      year: new Date().getFullYear(),
      last_15_years: this.generateLastYears(15),
      car_makes: [
        "Abarth",
        "Acura",
        "Alfa",
        "Aston",
        "Audi",
        "Bentley",
        "BMW",
        "Buick",
        "Cadillac",
        "Chevrolet",
        "Chrysler",
        "Citroen",
        "Dacia",
        "Ferrari",
        "Fiat",
        "Ford",
        "GMC",
        "Honda",
        "Hummer",
        "Hyundai",
        "Infiniti",
        "Isuzu",
        "Jaguar",
        "Jeep",
        "Kia",
        "Lamborghini",
        "Lancia",
        "Land",
        "Lexus",
        "Lincoln",
        "Lotus",
        "Maserati",
        "Mazda",
        "Mercedes",
        "Mercury",
        "Mini",
        "Mitsubishi",
        "Nissan",
        "Opel",
        "Peugeot",
        "Pontiac",
        "Porsche",
        "Ram",
        "Renault",
        "Saab",
        "Saturn",
        "Scion",
        "Seat",
        "Skoda",
        "Smart",
        "SsangYong",
        "Subaru",
        "Suzuki",
        "Tesla",
        "Toyota",
        "Volkswagen",
        "Volvo",
        "Wiesmann",
      ],
    };
  },
  validations() {
    return {
      formData: {
        yom: { required },
        reg_no: { required },
        car_make: { required },
        car_model: { required },
        car_value: { required },
        customer_name: { required },
        email: { required, email },
        phone: { required, minLength: minLength(8) },
      },
    };
  },
  async created() {
    // this.getWeeklyAnalysis();
    // this.getDailyAnalysis();
    localStorage.setItem("navbar_key", 1);

    let status = await AuthService.checkAuth();
    if (status == 401) {
      localStorage.removeItem("user");
      alert("Session expired");
      this.$router.push("/login");
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        console.log(newVal);
      },
      deep: true,
    },
  },
  methods: {
    validatePhone(num) {
      num = num.slice(1).replace(/ /g, "");
      console.log(num);
      let isnum = /^\d+$/.test(num);
      if (isnum) {
        // val is a number
        console.log("The number is valid.");
      } else {
        this.formData.phone = "";
        toast.error("Phone number invalid!");
      }
    },
    makeSelected(e) {
      console.log(e);
      this.formData.car_make = e;
      this.v$.formData.car_make.$touch();
    },
    makeUpdated(e) {
      console.log(e.input);
      this.formData.car_make = e.input;
      this.v$.formData.car_make.$touch();
    },
    resetForm() {
      this.formData = {
        car_value: "",
        insurance_type: "MPC",
      };
      this.$v.$reset();
    },
    saveCustomer() {
      this.v$.formData.yom.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.email.$touch();
      this.v$.formData.reg_no.$touch();
      this.v$.formData.car_make.$touch();
      this.v$.formData.car_model.$touch();
      this.v$.formData.car_value.$touch();
      this.v$.formData.customer_name.$touch();
      if (this.v$.formData.$errors.length > 0) {
        window.scrollTo(0, 0);
        return;
      }
      this.formData.first_name = this.formData.customer_name.split(" ")[0];
      this.formData.last_name = this.formData.customer_name.split(" ")[1];
      this.formData.mobile_no = this.formData.phone;
      this.$store.commit("SET_LOADING", true);
      CustomerService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message);
            this.$store.commit("SET_LOADING", false);
            setTimeout(() => {
              this.$router.push("customer.index");
            }, 2000);
          } else {
            toast.error(response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            console.log("customer");
            let error_msg = "";
            if (error.response.data.errors.email)
              error_msg = error.response.data.errors.email[0];
            if (error.response.data.errors.reg_no)
              error_msg = error.response.data.errors.reg_no[0];
            if (error.response.data.errors.mobile_no)
              error_msg = error.response.data.errors.mobile_no[0];
            toast.error(error_msg || error.response.data.message);

            this.$store.commit("SET_LOADING", false);
          } else if (error.response.status == 502) {
            toast.info(error.response.data.message);
            this.$store.commit("SET_LOADING", false);
          } else {
            toast.error(error.response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        }
      );
      window.scrollTo(0, 0);
    },
    async generateDownloadUrl() {
      let data = {
        form_data: this.formData,
        // providers: this.providers,
      };
      let plaintext = JSON.stringify(data);
      let base_64 = await window.btoa(plaintext);
      this.download_url =
        process.env.VUE_APP_BASE_URL + "quotation-summary/" + base_64;
      this.formData.base_64 = base_64;
    },
    shareSummary() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.shareSummaryEmail(this.formData.base_64).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success("Email sent successfully!");
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          toast.error("Snap! A server error occurred!");
        }
      );
    },
    async generateReport() {
      let printable = await document.getElementById("element-to-print");
      printable.classList.remove("d-none");
      var element = await document.getElementById("element-to-print");
      await html2pdf(element, {
        margin: 0.1,
        filename: `JIA Summary ${this.formData.customer_name}.pdf`,
        pagebreak: { after: ".sautDePage" },
        image: {
          type: "jpeg",
          quality: 2,
        },
        html2canvas: {
          scale: 2,
          letterRendering: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      });

      printable.classList.add("d-none");
    },
    phoneUpdated(e) {
      this.v$.formData.phone.$touch();
      console.log(e.target.value);
      this.formData.phone = e.target.value;
      this.validatePhone(e.target.value);
    },
    customerNameUpdated(e) {
      this.v$.formData.customer_name.$touch();
    },
    regNoUpdated() {
      this.v$.formData.reg_no.$touch();
    },
    car_valueUpdated() {
      this.v$.formData.car_value.$touch();
    },
    emailUpdated() {
      this.v$.formData.email.$touch();
    },
    insurance_typeUpdated() {
      this.v$.formData.insurance_type.$touch();
    },
    async showRow(item) {
      let data = {
        id: item.id,
        form_data: this.formData,
        premium_provider: item,
      };
      console.log("item", item);
      console.log(data);
      const encoded = await window.btoa(JSON.stringify(data));
      this.iframe_route =
        process.env.VUE_APP_ROOT_URL + "#/quotation/" + encoded;
      this.iframe_key = encoded;
      var myModal = new bootstrap.Modal(
        document.getElementById("quotationpreviewModal")
      );
      myModal.show();

      // $("#quotationpreviewModal").show();
      // window.open(route.href);
    },
    yearSelected(e) {
      this.formData.yom = e.target.value;
      this.v$.formData.yom.$touch();
      this.getQuotations();
    },
    goToCustomers() {},
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    getQuotations() {
      this.v$.formData.yom.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.car_value.$touch();
      // this.v$.formData.reg_no.$touch();
      // this.v$.formData.email.$touch();
      console.group("Valid");
      if (this.formData.car_value > 5000000) {
        toast.error("Please gnerate a custom quote for values above 5M!", {
          autoClose: 6000,
        });
      }
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      return ProviderService.quote(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.providers = response.data.data;
            this.generateDownloadUrl(this.providers);
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
          // toast.success("Horaay! Quotation generated successfully!");
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
          toast.error("Snap! A server error occurred!");
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
