<template>
  <div>
    <!-- Quotations Downloads -->
    <div
      class="card mb-3"
      id="ordersTable"
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-building text-warning"></i>
              Quotation History
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Quotations Generated & Shared
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex"></div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <div class="table-responsive scrollbar">
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <!-- <th class="sort" data-sort="name">Item No.</th> -->
                  <th class="sort" data-sort="age">Name</th>
                  <th class="sort" data-sort="age">Served by</th>
                  <th class="sort" data-sort="age">Status</th>
                  <th class="sort" data-sort="age">Amount</th>
                  <th class="sort" data-sort="email">Date</th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(item, i) in resources" :key="item.ID">
                  <!-- <td>{{ item.id }}</td> -->
                  <td><a :href="`${base_url}history-download/${item.name}`" _target="blank"> {{ item.name }}</a></td>
                  <td>{{ item.created_by }}</td>
                  <td>
                    <span
                      v-if="item.status == 0"
                      class="badge badge rounded-pill d-block badge-soft-secondary"
                      >Draft
                      <span
                        class="ms-1 fas fa-ban"
                        data-fa-transform="shrink-2"
                      >
                      </span>
                    </span>
                    <span
                      v-if="item.status == 1"
                      class="badge badge rounded-pill d-block badge-soft-danger"
                    >
                      Completed
                      <span
                        class="ms-1 fas fa-stream"
                        data-fa-transform="shrink-2"
                      >
                      </span>
                    </span>
                  </td>
                  <td>{{ item.total }}</td>
                  <td>{{ formatDate(item.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page"
              :records="records"
              :per-page="per_page"
              @paginate="myCallback"
            />
          </div>
        </div>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Summary Downloads  -->
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-building text-warning"></i>
              Summary History
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                Summary Generated & Shared
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex"></div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <div class="table-responsive scrollbar">
          <div class="table-responsive scrollbar">
            <table class="table table-bordered table-striped fs--1 mb-0">
              <thead class="bg-200 text-900">
                <tr>
                  <!-- <th class="sort" data-sort="name">Item No.</th> -->
                  <th class="sort" data-sort="age">Name</th>
                  <th class="sort" data-sort="age">Served by</th>
                  <th class="sort" data-sort="email">Date</th>
                </tr>
              </thead>
              <tbody class="list">
                <tr v-for="(item, i) in resources_s" :key="item.ID">
                  <!-- <td>{{ item.id }}</td> -->
                  <td><a :href="`${base_url}history-download/${item.name}`" _target="blank"> {{ item.name }}</a></td>
                  <td>{{ item.created_by }}</td>
                  <td>{{ formatDate(item.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <pagination
              v-model="current_page_s"
              :records="records_s"
              :per-page="per_page_s"
              @paginate="myCallbackSummary"
            />
          </div>
        </div>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AppBar from "@/components/AppBar.vue";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ProviderService from "../services/provider.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";
import Pagination from "v-pagination-3";

const now = new Date();

export default {
  mixins: [mixins],
  name: "PremiumProvidersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        paybill_no: { required },
        ins_500k_to_1m_rate: { required },
        ins_500k_to_1m_basic_minimum: { required },
        ins_500k_to_1m_excess_protector: { required },
        ins_500k_to_1m_xp_minimum: { required },
        ins_500k_to_1m_pvt_rate: { required },
        ins_500k_to_1m_pvt_minimum: { required },
        ins_500k_to_1m_courtesy_car: { required },
        ins_1m_to_1p5_rate: { required },
        ins_1m_to_1p5_basic_minimum: { required },
        ins_1m_to_1p5_excess_protector: { required },
        ins_1m_to_1p5_xp_minimum: { required },
        ins_1m_to_1p5_pvt_rate: { required },
        ins_1m_to_1p5_pvt_minimum: { required },
        ins_1m_to_1p5_courtesy_car: { required },
        ins_1p5_to_2p5_rate: { required },
        ins_1p5_to_2p5_basic_minimum: { required },
        ins_1p5_to_2p5_excess_protector: { required },
        ins_1p5_to_2p5_xp_minimum: { required },
        ins_1p5_to_2p5_pvt_rate: { required },
        ins_1p5_to_2p5_pvt_minimum: { required },
        ins_1p5_to_2p5_courtesy_car: { required },
        ins_2p5_to_5m_rate: { required },
        ins_2p5_to_5m_basic_minimum: { required },
        ins_2p5_to_5m_excess_protector: { required },
        ins_2p5_to_5m_xp_minimum: { required },
        ins_2p5_to_5m_pvt_rate: { required },
        ins_2p5_to_5m_pvt_minimum: { required },
        ins_2p5_to_5m_courtesy_car: { required },
        third_party: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    Pagination,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      records: 0,
      per_page: 25,
      current_page: 0,
      records_s: 0,
      per_page_s: 25,
      current_page_s: 0,
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      base_url: process.env.VUE_APP_BASE_URL,
      orders_table_key: 0,
      searchField: ["name", "paybill_no"],
      headers: [
        { text: "Action", value: "action" },
        // { text: "#", value: "id", sortable: true },
        { text: "Logo", value: "image_url", sortable: true, width: 35 },
        { text: "Name", value: "name", sortable: true, width: 350 },
        {
          text: "Third Party (Ksh)",
          value: "third_party",
          sortable: true,
          width: 150,
        },
        { text: "Paybill", value: "paybill_no", sortable: true },
        // ins_500k_to_1m_rate
        { text: "500k-1M Rate", value: "ins_500k_to_1m_rate", sortable: true },
        {
          text: "500k-1M B.Min",
          value: "ins_500k_to_1m_basic_minimum",
          sortable: true,
        },
        {
          text: "500k-1M XP",
          value: "ins_500k_to_1m_excess_protector",
          sortable: true,
        },
        {
          text: "500k-1M XP.Min",
          value: "ins_500k_to_1m_xp_minimum",
          sortable: true,
        },
        {
          text: "500k-1M PVT",
          value: "ins_500k_to_1m_pvt_rate",
          sortable: true,
        },
        {
          text: "500k-1M PVT.Min",
          value: "ins_500k_to_1m_pvt_minimum",
          sortable: true,
        },
        {
          text: "500k-1M Courtesy",
          value: "ins_500k_to_1m_courtesy_car",
          sortable: true,
        },
        // ins_1m_to_1p5_rate
        { text: "500k-1M Rate", value: "ins_1m_to_1p5_rate", sortable: true },
        {
          text: "500k-1M B.Min",
          value: "ins_1m_to_1p5_basic_minimum",
          sortable: true,
        },
        {
          text: "500k-1M XP",
          value: "ins_1m_to_1p5_excess_protector",
          sortable: true,
        },
        {
          text: "500k-1M XP.Min",
          value: "ins_1m_to_1p5_xp_minimum",
          sortable: true,
        },
        {
          text: "500k-1M PVT",
          value: "ins_1m_to_1p5_pvt_rate",
          sortable: true,
        },
        {
          text: "500k-1M PVT.Min",
          value: "ins_1m_to_1p5_pvt_minimum",
          sortable: true,
        },
        {
          text: "500k-1M Courtesy",
          value: "ins_1m_to_1p5_courtesy_car",
          sortable: true,
        },
        // ins_1p5_to_2p5
        {
          text: "1.5M-2.5M Rate",
          value: "ins_1p5_to_2p5_rate",
          sortable: true,
        },
        {
          text: "1.5M-2.5M B.Min",
          value: "ins_1p5_to_2p5_basic_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M XP",
          value: "ins_1p5_to_2p5_excess_protector",
          sortable: true,
        },
        {
          text: "1.5M-2.5M XP.Min",
          value: "ins_1p5_to_2p5_xp_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M PVT",
          value: "ins_1p5_to_2p5_pvt_rate",
          sortable: true,
        },
        {
          text: "1.5M-2.5M PVT.Min",
          value: "ins_1p5_to_2p5_pvt_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M Courtesy",
          value: "ins_1p5_to_2p5_courtesy_car",
          sortable: true,
        },
        // ins_2p5_to_5m
        { text: "2.5M-5M Rate", value: "ins_2p5_to_5m_rate", sortable: true },
        {
          text: "2.5M-5M B.Min",
          value: "ins_2p5_to_5m_basic_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M XP",
          value: "ins_2p5_to_5m_excess_protector",
          sortable: true,
        },
        {
          text: "2.5M-5M XP.Min",
          value: "ins_2p5_to_5m_xp_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M PVT",
          value: "ins_2p5_to_5m_pvt_rate",
          sortable: true,
        },
        {
          text: "2.5M-5M PVT.Min",
          value: "ins_2p5_to_5m_pvt_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M Courtesy",
          value: "ins_2p5_to_5m_courtesy_car",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.getData();
    this.getDataSummary();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    myCallback(e) {
      this.getDataPage(e);
    },
    myCallbackSummary(e) {
      this.getDataPageSummary(e);
    },
    addNew() {
      this.formData = {};
    },
    setSelectedItem(item) {
      this.formData = item;
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.history().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataSummary() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historySummary().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources_s = response.data.data.data;
            this.records_s = response.data.data.total;
            this.current_page_s = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataPage(page) {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historyPage(page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDataPageSummary(page) {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.historyPageSummary(page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
