import moment from "moment";

export default {
  created: function () {},
  methods: {
    generateLastYears(count) {
      let year = new Date().getFullYear();
      let last_years = [];
      for (let index = 0; index < count; index++) {
        last_years.push(year - index);
      }
      return last_years;
    },
    getRandomColors() {
      let colors = [
        "dot bg-primary",
        "dot bg-info",
        "dot bg-warning",
        "dot bg-primary",
        "dot bg-danger",
      ];
      return colors[Math.floor(Math.random() * colors.length)];
    },
    forceReload() {
      if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        this.$store.commit("SET_LOADING", false);
      } else {
        this.$store.commit("SET_LOADING", false);
        localStorage.setItem("reloaded", "1");
        this.$router.go();
      }
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      // return "Ksh. " + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    formatDate(input) {
      input = new Date(input);
      input.setHours(input.getHours() - 4);
      return moment(input).format("DD-MM-YYYY h:mm:ss");
    },
    formatDateHuman(input) {
      return moment(input).format("MMMM Do YYYY");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    generateRandom(min, max) {
      return Math.floor(min + Math.random() * max);
    },
    getTimeGreetings() {
      const time = new Date().getHours();
      let greeting;
      if (time < 12) greeting = "Good Morning";
      else if (time >= 12 && time <= 17) greeting = "Good Afternoon";
      else if (time >= 17 && time <= 24) greeting = "Good Evening";
      return greeting;
    },
    hasRight(slug) {
      let userRole = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")).permissions
        : [];
      return Object.keys(userRole).includes(slug);
    },
    detectOS() {
      var detectedOS = "Unknown OS";
      if (navigator.appVersion.indexOf("Mac") != -1) detectedOS = "MacOS";
      if (navigator.appVersion.indexOf("Win") != -1) detectedOS = "Windows";
      if (navigator.appVersion.indexOf("Linux") != -1) detectedOS = "Linux";
      return detectedOS;
    },
    detectBrowser() {
      let userAgentString = 
          navigator.userAgent;
      var detectedBrowser = "Unknown Browser";
      if(userAgentString.indexOf("Chrome") > -1) detectedBrowser = "Chrome";
      if(userAgentString.indexOf("MSIE") > -1 ||  userAgentString.indexOf("rv:") > -1) detectedBrowser = "Internet Exlorer";
      if(userAgentString.indexOf("Firefox") > -1) detectedBrowser = "Firefox";
      if(userAgentString.indexOf("Safari") > -1) detectedBrowser = "Safari";
      if(userAgentString.indexOf("OP") > -1) detectedBrowser = "Opera";
      return detectedBrowser;
    },
  },
  computed: {
    today() {
      return new Date().toDateString();
    },
    user() {
      return this.$store.state.auth.user;
    },
    table() {
      return localStorage.getItem("table") == null
        ? {}
        : JSON.parse(localStorage.getItem("table"));
    },
  },
};
