<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-building text-warning"></i>
              Premium Providers
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Insurance companies & rates view
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1">
                <input
                  class="form-control search-input fuzzy-search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  v-model="searchValue"
                />
                <span class="fas fa-search search-box-icon"></span>
              </div>

              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-circle h2"
                type="button"
                @click="addNew"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-plus" data-fa-transform=""></span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-id="item">
            {{ item.id }}
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name }}</strong>
              <p class="mb-0 text-500 text-wrap">
                {{ formatDateHuman(item.created_at) }}
              </p>
            </div>
          </template>
          <template #item-image_url="item">
            <img :src="item.image_url" width="32" />
          </template>
          <template #item-third_party="item">
            {{ formatToCurrency(item.third_party) }}
          </template>
          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    @click="setSelectedItem(item)"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteRecource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
          <!-- ins_500k_to_1m_rate -->
          <template #item-ins_500k_to_1m_rate="item">
            <span class="text-success"> {{ item.ins_500k_to_1m_rate }}% </span>
          </template>
          <template #item-ins_500k_to_1m_basic_minimum="item">
            <span class="text-success">
              {{ formatToCurrency(item.ins_500k_to_1m_basic_minimum) }}
            </span>
          </template>
          <template #item-ins_500k_to_1m_excess_protector="item">
            <span class="text-success">
              {{ item.ins_500k_to_1m_excess_protector }}%
            </span>
          </template>
          <template #item-ins_500k_to_1m_xp_minimum="item">
            <span class="text-success">
              {{ formatToCurrency(item.ins_500k_to_1m_xp_minimum) }}
            </span>
          </template>
          <template #item-ins_500k_to_1m_pvt_rate="item">
            <span class="text-success">
              {{ item.ins_500k_to_1m_pvt_rate }}%
            </span>
          </template>
          <template #item-ins_500k_to_1m_pvt_minimum="item">
            <span class="text-success">
              {{ formatToCurrency(item.ins_500k_to_1m_pvt_minimum) }}
            </span>
          </template>
          <template #item-ins_500k_to_1m_courtesy_car="item">
            <span class="text-success">
              {{ formatToCurrency(item.ins_500k_to_1m_courtesy_car) }}
            </span>
          </template>
          <!-- ins_1m_to_1p5_rate -->
          <template #item-ins_1m_to_1p5_rate="item">
            <span class="text-info"> {{ item.ins_1m_to_1p5_rate }}% </span>
          </template>
          <template #item-ins_1m_to_1p5_basic_minimum="item">
            <span class="text-info">
              {{ formatToCurrency(item.ins_1m_to_1p5_basic_minimum) }}
            </span>
          </template>
          <template #item-ins_1m_to_1p5_excess_protector="item">
            <span class="text-info">
              {{ item.ins_1m_to_1p5_excess_protector }}%
            </span>
          </template>
          <template #item-ins_1m_to_1p5_xp_minimum="item">
            <span class="text-info">
              {{ formatToCurrency(item.ins_1m_to_1p5_xp_minimum) }}
            </span>
          </template>
          <template #item-ins_1m_to_1p5_pvt_rate="item">
            <span class="text-info"> {{ item.ins_1m_to_1p5_pvt_rate }}% </span>
          </template>
          <template #item-ins_1m_to_1p5_pvt_minimum="item">
            <span class="text-info">
              {{ formatToCurrency(item.ins_1m_to_1p5_pvt_minimum) }}
            </span>
          </template>
          <template #item-ins_1m_to_1p5_courtesy_car="item">
            <span class="text-info">
              {{ formatToCurrency(item.ins_1m_to_1p5_courtesy_car) }}
            </span>
          </template>
          <!-- ins_1p5_to_2p5_rate -->
          <template #item-ins_1p5_to_2p5_rate="item">
            <span class="text-warning"> {{ item.ins_1m_to_1p5_rate }}% </span>
          </template>
          <template #item-ins_1p5_to_2p5_basic_minimum="item">
            <span class="text-warning">
              {{ formatToCurrency(item.ins_1m_to_1p5_basic_minimum) }}
            </span>
          </template>
          <template #item-ins_1p5_to_2p5_excess_protector="item">
            <span class="text-warning">
              {{ item.ins_1m_to_1p5_excess_protector }}%
            </span>
          </template>
          <template #item-ins_1p5_to_2p5_xp_minimum="item">
            <span class="text-warning">
              {{ formatToCurrency(item.ins_1m_to_1p5_xp_minimum) }}
            </span>
          </template>
          <template #item-ins_1p5_to_2p5_pvt_rate="item">
            <span class="text-warning">
              {{ item.ins_1m_to_1p5_pvt_rate }}%
            </span>
          </template>
          <template #item-ins_1p5_to_2p5_pvt_minimum="item">
            <span class="text-warning">
              {{ formatToCurrency(item.ins_1m_to_1p5_pvt_minimum) }}
            </span>
          </template>
          <template #item-ins_1p5_to_2p5_courtesy_car="item">
            <span class="text-warning">
              {{ formatToCurrency(item.ins_1m_to_1p5_courtesy_car) }}
            </span>
          </template>
          <!-- ins_2p5_to_5m -->
          <template #item-ins_2p5_to_5m_rate="item">
            <span class="text-primary"> {{ item.ins_1m_to_1p5_rate }}% </span>
          </template>
          <template #item-ins_2p5_to_5m_basic_minimum="item">
            <span class="text-primary">
              {{ formatToCurrency(item.ins_1m_to_1p5_basic_minimum) }}
            </span>
          </template>
          <template #item-ins_2p5_to_5m_excess_protector="item">
            <span class="text-primary">
              {{ item.ins_1m_to_1p5_excess_protector }}%
            </span>
          </template>
          <template #item-ins_2p5_to_5m_xp_minimum="item">
            <span class="text-primary">
              {{ formatToCurrency(item.ins_2p5_to_5m_xp_minimum) }}
            </span>
          </template>
          <template #item-ins_2p5_to_5m_pvt_rate="item">
            <span class="text-primary">
              {{ item.ins_1m_to_1p5_pvt_rate }}%
            </span>
          </template>
          <template #item-ins_2p5_to_5m_pvt_minimum="item">
            <span class="text-primary">
              {{ formatToCurrency(item.ins_2p5_to_5m_pvt_minimum) }}
            </span>
          </template>
          <template #item-ins_2p5_to_5m_courtesy_car="item">
            <span class="text-primary">
              {{ formatToCurrency(item.ins_1m_to_1p5_courtesy_car) }}
            </span>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>

    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-1 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <span class="fw-900 fw-bold" id="staticBackdropLabel"
                >Edit Premium</span
              >
            </div>
            <div class="row px-4">
              <!-- Row 1  -->
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label class="col-form-labe" for="recipient-name">Name</label>
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  :placeholder="formData.name"
                  v-model="formData.name"
                  :class="
                    v$.formData.name.$dirty && v$.formData.name.$invalid
                      ? `is-invalid`
                      : v$.formData.name.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.name.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Paybill No</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  v-model="formData.paybill_no"
                />
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text">Image</label>
                <input
                  class="form-control"
                  id="image"
                  type="file"
                  @change="uploadImage"
                />
              </div>

              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Third Party</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="number"
                  v-model="formData.third_party"
                  :class="
                    v$.formData.third_party.$dirty &&
                    v$.formData.third_party.$invalid
                      ? `is-invalid`
                      : v$.formData.third_party.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.third_party.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <!-- Row 2  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 500K to 1M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_500k_to_1m_rate"
                  type="text"
                  v-model="formData.ins_500k_to_1m_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_basic_minimum"
                  type="text"
                  v-model="formData.ins_500k_to_1m_basic_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_excess_protector"
                  type="text"
                  v-model="formData.ins_500k_to_1m_excess_protector"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_xp_minimum"
                  type="text"
                  v-model="formData.ins_500k_to_1m_xp_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_pvt_rate"
                  type="text"
                  v-model="formData.ins_500k_to_1m_pvt_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_pvt_minimum"
                  type="text"
                  v-model="formData.ins_500k_to_1m_pvt_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_courtesy_car"
                  type="text"
                  v-model="formData.ins_500k_to_1m_courtesy_car"
                />
              </div>
              <!-- Row 3  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 1M to 1.5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_rate"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_basic_minimum"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_basic_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_excess_protector"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_excess_protector"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_xp_minimum"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_xp_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_pvt_rate"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_pvt_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_pvt_minimum"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_pvt_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_courtesy_car"
                  type="text"
                  v-model="formData.ins_1m_to_1p5_courtesy_car"
                />
              </div>
              <!-- Row 4  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 1.5M to 2.5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_rate"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_basic_minimum"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_basic_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_excess_protector"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_excess_protector"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_xp_minimum"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_xp_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_pvt_rate"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_pvt_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_pvt_minimum"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_pvt_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_courtesy_car"
                  type="text"
                  v-model="formData.ins_1p5_to_2p5_courtesy_car"
                />
              </div>
              <!-- Row 5  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 2.5M to 5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_rate"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_basic_minimum"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_basic_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_excess_protector"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_excess_protector"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minimum XP</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_xp_minimum"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_xp_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_pvt_rate"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_pvt_rate"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_pvt_minimum"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_pvt_minimum"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_courtesy_car"
                  type="text"
                  v-model="formData.ins_2p5_to_5m_courtesy_car"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer mt-2 bg-light rounded-bottom-lg py-3 px-3">
            <button
              class="btn btn-falcon-success ml-auto"
              @click="updateResource(formData)"
            >
              <i class="fa fa-save"></i>
              Update premium
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End edit modal  -->

    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">
                Add Premium Provider
              </h4>
            </div>
            <div class="row px-4">
              <!-- Row 1  -->
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label class="col-form-labe" for="recipient-name">Name</label>
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  placeholder="e.g NIC INSURANCE LTD"
                  v-model="formData.name"
                  :class="
                    v$.formData.name.$dirty && v$.formData.name.$invalid
                      ? `is-invalid`
                      : v$.formData.name.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.name.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Paybill No</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="text"
                  v-model="formData.paybill_no"
                  :class="
                    v$.formData.paybill_no.$dirty &&
                    v$.formData.paybill_no.$invalid
                      ? `is-invalid`
                      : v$.formData.paybill_no.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.paybill_no.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <label class="col-form-label" for="message-text">Image</label>
                <input
                  class="form-control"
                  id="image"
                  type="file"
                  @change="uploadImage"
                />
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <label class="col-form-label" for="message-text"
                  >Third Party</label
                >
                <input
                  class="form-control"
                  id="recipient-name"
                  type="number"
                  v-model="formData.third_party"
                  :class="
                    v$.formData.third_party.$dirty &&
                    v$.formData.third_party.$invalid
                      ? `is-invalid`
                      : v$.formData.third_party.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.third_party.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <!-- Row 2  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 500K to 1M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_500k_to_1m_rate"
                  type="number"
                  v-model="formData.ins_500k_to_1m_rate"
                  :class="
                    v$.formData.ins_500k_to_1m_rate.$dirty &&
                    v$.formData.ins_500k_to_1m_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_basic_minimum"
                  type="number"
                  v-model="formData.ins_500k_to_1m_basic_minimum"
                  :class="
                    v$.formData.ins_500k_to_1m_basic_minimum.$dirty &&
                    v$.formData.ins_500k_to_1m_basic_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_basic_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_basic_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_excess_protector"
                  type="number"
                  v-model="formData.ins_500k_to_1m_excess_protector"
                  :class="
                    v$.formData.ins_500k_to_1m_excess_protector.$dirty &&
                    v$.formData.ins_500k_to_1m_excess_protector.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_excess_protector.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_excess_protector
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_xp_minimum"
                  type="number"
                  v-model="formData.ins_500k_to_1m_xp_minimum"
                  :class="
                    v$.formData.ins_500k_to_1m_xp_minimum.$dirty &&
                    v$.formData.ins_500k_to_1m_xp_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_xp_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_xp_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_pvt_rate"
                  type="number"
                  v-model="formData.ins_500k_to_1m_pvt_rate"
                  :class="
                    v$.formData.ins_500k_to_1m_pvt_rate.$dirty &&
                    v$.formData.ins_500k_to_1m_pvt_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_pvt_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_pvt_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_pvt_minimum"
                  type="number"
                  v-model="formData.ins_500k_to_1m_pvt_minimum"
                  :class="
                    v$.formData.ins_500k_to_1m_pvt_minimum.$dirty &&
                    v$.formData.ins_500k_to_1m_pvt_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_pvt_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_pvt_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_500k_to_1m_courtesy_car"
                  type="number"
                  v-model="formData.ins_500k_to_1m_courtesy_car"
                  :class="
                    v$.formData.ins_500k_to_1m_courtesy_car.$dirty &&
                    v$.formData.ins_500k_to_1m_courtesy_car.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_500k_to_1m_courtesy_car.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_500k_to_1m_courtesy_car
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <!-- Row 3  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 1M to 1.5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_rate"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_rate"
                  :class="
                    v$.formData.ins_1m_to_1p5_rate.$dirty &&
                    v$.formData.ins_1m_to_1p5_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_basic_minimum"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_basic_minimum"
                  :class="
                    v$.formData.ins_1m_to_1p5_basic_minimum.$dirty &&
                    v$.formData.ins_1m_to_1p5_basic_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_basic_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_basic_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_excess_protector"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_excess_protector"
                  :class="
                    v$.formData.ins_1m_to_1p5_excess_protector.$dirty &&
                    v$.formData.ins_1m_to_1p5_excess_protector.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_excess_protector.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_excess_protector
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_xp_minimum"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_xp_minimum"
                  :class="
                    v$.formData.ins_1m_to_1p5_xp_minimum.$dirty &&
                    v$.formData.ins_1m_to_1p5_xp_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_xp_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_xp_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_pvt_rate"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_pvt_rate"
                  :class="
                    v$.formData.ins_1m_to_1p5_pvt_rate.$dirty &&
                    v$.formData.ins_1m_to_1p5_pvt_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_pvt_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_pvt_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_pvt_minimum"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_pvt_minimum"
                  :class="
                    v$.formData.ins_1m_to_1p5_pvt_minimum.$dirty &&
                    v$.formData.ins_1m_to_1p5_pvt_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_pvt_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_pvt_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_1m_to_1p5_courtesy_car"
                  type="number"
                  v-model="formData.ins_1m_to_1p5_courtesy_car"
                  :class="
                    v$.formData.ins_1m_to_1p5_courtesy_car.$dirty &&
                    v$.formData.ins_1m_to_1p5_courtesy_car.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1m_to_1p5_courtesy_car.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1m_to_1p5_courtesy_car
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <!-- Row 4  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 1.5M to 2.5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_rate"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_rate"
                  :class="
                    v$.formData.ins_1p5_to_2p5_rate.$dirty &&
                    v$.formData.ins_1p5_to_2p5_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_basic_minimum"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_basic_minimum"
                  :class="
                    v$.formData.ins_1p5_to_2p5_basic_minimum.$dirty &&
                    v$.formData.ins_1p5_to_2p5_basic_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_basic_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_basic_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_excess_protector"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_excess_protector"
                  :class="
                    v$.formData.ins_1p5_to_2p5_excess_protector.$dirty &&
                    v$.formData.ins_1p5_to_2p5_excess_protector.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_excess_protector.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_excess_protector
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_xp_minimum"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_xp_minimum"
                  :class="
                    v$.formData.ins_1p5_to_2p5_xp_minimum.$dirty &&
                    v$.formData.ins_1p5_to_2p5_xp_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_xp_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_xp_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_pvt_rate"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_pvt_rate"
                  :class="
                    v$.formData.ins_1p5_to_2p5_pvt_rate.$dirty &&
                    v$.formData.ins_1p5_to_2p5_pvt_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_pvt_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_pvt_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_pvt_minimum"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_pvt_minimum"
                  :class="
                    v$.formData.ins_1p5_to_2p5_pvt_minimum.$dirty &&
                    v$.formData.ins_1p5_to_2p5_pvt_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_pvt_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_pvt_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_1p5_to_2p5_courtesy_car"
                  type="number"
                  v-model="formData.ins_1p5_to_2p5_courtesy_car"
                  :class="
                    v$.formData.ins_1p5_to_2p5_courtesy_car.$dirty &&
                    v$.formData.ins_1p5_to_2p5_courtesy_car.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_courtesy_car.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_1p5_to_2p5_courtesy_car
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <!-- Row 5  -->
              <div class="mt-3">
                <h6 class="mt-2"><u>Limit 2.5M to 5M</u></h6>
              </div>
              <div class="col-sm-12 col-md-1 col-lg-1 col-xl-1 mr-n2">
                <label class="col-form-label" for="message-text">Rate(%)</label>
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_rate"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_rate"
                  :class="
                    v$.formData.ins_2p5_to_5m_rate.$dirty &&
                    v$.formData.ins_2p5_to_5m_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_1p5_to_2p5_courtesy_car.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Basic Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_basic_minimum"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_basic_minimum"
                  :class="
                    v$.formData.ins_2p5_to_5m_basic_minimum.$dirty &&
                    v$.formData.ins_2p5_to_5m_basic_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_basic_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_basic_minimum
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Excess.P</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_excess_protector"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_excess_protector"
                  :class="
                    v$.formData.ins_2p5_to_5m_excess_protector.$dirty &&
                    v$.formData.ins_2p5_to_5m_excess_protector.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_excess_protector.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_excess_protector
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Minumum XP</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_xp_minimum"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_xp_minimum"
                  :class="
                    v$.formData.ins_2p5_to_5m_xp_minimum.$dirty &&
                    v$.formData.ins_2p5_to_5m_xp_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_xp_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_xp_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Rate(%)</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_pvt_rate"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_pvt_rate"
                  :class="
                    v$.formData.ins_2p5_to_5m_pvt_rate.$dirty &&
                    v$.formData.ins_2p5_to_5m_pvt_rate.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_pvt_rate.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_pvt_rate.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >PVT Minimum</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_pvt_minimum"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_pvt_minimum"
                  :class="
                    v$.formData.ins_2p5_to_5m_pvt_minimum.$dirty &&
                    v$.formData.ins_2p5_to_5m_pvt_minimum.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_pvt_minimum.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_pvt_minimum.$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
              <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 mx-n2">
                <label class="col-form-label" for="message-text"
                  >Courtesy Car</label
                >
                <input
                  class="form-control"
                  id="ins_2p5_to_5m_courtesy_car"
                  type="number"
                  v-model="formData.ins_2p5_to_5m_courtesy_car"
                  :class="
                    v$.formData.ins_2p5_to_5m_courtesy_car.$dirty &&
                    v$.formData.ins_2p5_to_5m_courtesy_car.$invalid
                      ? `is-invalid`
                      : v$.formData.ins_2p5_to_5m_courtesy_car.$dirty
                      ? `is-valid`
                      : ``
                  "
                />
                <div
                  v-for="error of v$.formData.ins_2p5_to_5m_courtesy_car
                    .$errors"
                  :key="error.$uid"
                  class="invalid-feedback"
                  style="color: red"
                >
                  This field is invalid
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer mt-2 bg-light rounded-bottom-lg py-3 px-3">
            <button
              class="btn btn-falcon-primary ml-auto"
              @click="saveResource"
            >
              <i class="fa fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import AppBar from "@/components/AppBar.vue";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ProviderService from "../services/provider.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";

const now = new Date();

export default {
  mixins: [mixins],
  name: "PremiumProvidersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        name: { required },
        paybill_no: { required },
        ins_500k_to_1m_rate: { required },
        ins_500k_to_1m_basic_minimum: { required },
        ins_500k_to_1m_excess_protector: { required },
        ins_500k_to_1m_xp_minimum: { required },
        ins_500k_to_1m_pvt_rate: { required },
        ins_500k_to_1m_pvt_minimum: { required },
        ins_500k_to_1m_courtesy_car: { required },
        ins_1m_to_1p5_rate: { required },
        ins_1m_to_1p5_basic_minimum: { required },
        ins_1m_to_1p5_excess_protector: { required },
        ins_1m_to_1p5_xp_minimum: { required },
        ins_1m_to_1p5_pvt_rate: { required },
        ins_1m_to_1p5_pvt_minimum: { required },
        ins_1m_to_1p5_courtesy_car: { required },
        ins_1p5_to_2p5_rate: { required },
        ins_1p5_to_2p5_basic_minimum: { required },
        ins_1p5_to_2p5_excess_protector: { required },
        ins_1p5_to_2p5_xp_minimum: { required },
        ins_1p5_to_2p5_pvt_rate: { required },
        ins_1p5_to_2p5_pvt_minimum: { required },
        ins_1p5_to_2p5_courtesy_car: { required },
        ins_2p5_to_5m_rate: { required },
        ins_2p5_to_5m_basic_minimum: { required },
        ins_2p5_to_5m_excess_protector: { required },
        ins_2p5_to_5m_xp_minimum: { required },
        ins_2p5_to_5m_pvt_rate: { required },
        ins_2p5_to_5m_pvt_minimum: { required },
        ins_2p5_to_5m_courtesy_car: { required },
        third_party: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "paybill_no"],
      headers: [
        { text: "Action", value: "action" },
        // { text: "#", value: "id", sortable: true },
        { text: "Logo", value: "image_url", sortable: true, width: 35 },
        { text: "Name", value: "name", sortable: true, width: 350 },
        {
          text: "Third Party (Ksh)",
          value: "third_party",
          sortable: true,
          width: 150,
        },
        { text: "Paybill", value: "paybill_no", sortable: true },
        // ins_500k_to_1m_rate
        { text: "500k-1M Rate", value: "ins_500k_to_1m_rate", sortable: true },
        {
          text: "500k-1M B.Min",
          value: "ins_500k_to_1m_basic_minimum",
          sortable: true,
        },
        {
          text: "500k-1M XP",
          value: "ins_500k_to_1m_excess_protector",
          sortable: true,
        },
        {
          text: "500k-1M XP.Min",
          value: "ins_500k_to_1m_xp_minimum",
          sortable: true,
        },
        {
          text: "500k-1M PVT",
          value: "ins_500k_to_1m_pvt_rate",
          sortable: true,
        },
        {
          text: "500k-1M PVT.Min",
          value: "ins_500k_to_1m_pvt_minimum",
          sortable: true,
        },
        {
          text: "500k-1M Courtesy",
          value: "ins_500k_to_1m_courtesy_car",
          sortable: true,
        },
        // ins_1m_to_1p5_rate
        { text: "500k-1M Rate", value: "ins_1m_to_1p5_rate", sortable: true },
        {
          text: "500k-1M B.Min",
          value: "ins_1m_to_1p5_basic_minimum",
          sortable: true,
        },
        {
          text: "500k-1M XP",
          value: "ins_1m_to_1p5_excess_protector",
          sortable: true,
        },
        {
          text: "500k-1M XP.Min",
          value: "ins_1m_to_1p5_xp_minimum",
          sortable: true,
        },
        {
          text: "500k-1M PVT",
          value: "ins_1m_to_1p5_pvt_rate",
          sortable: true,
        },
        {
          text: "500k-1M PVT.Min",
          value: "ins_1m_to_1p5_pvt_minimum",
          sortable: true,
        },
        {
          text: "500k-1M Courtesy",
          value: "ins_1m_to_1p5_courtesy_car",
          sortable: true,
        },
        // ins_1p5_to_2p5
        {
          text: "1.5M-2.5M Rate",
          value: "ins_1p5_to_2p5_rate",
          sortable: true,
        },
        {
          text: "1.5M-2.5M B.Min",
          value: "ins_1p5_to_2p5_basic_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M XP",
          value: "ins_1p5_to_2p5_excess_protector",
          sortable: true,
        },
        {
          text: "1.5M-2.5M XP.Min",
          value: "ins_1p5_to_2p5_xp_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M PVT",
          value: "ins_1p5_to_2p5_pvt_rate",
          sortable: true,
        },
        {
          text: "1.5M-2.5M PVT.Min",
          value: "ins_1p5_to_2p5_pvt_minimum",
          sortable: true,
        },
        {
          text: "1.5M-2.5M Courtesy",
          value: "ins_1p5_to_2p5_courtesy_car",
          sortable: true,
        },
        // ins_2p5_to_5m
        { text: "2.5M-5M Rate", value: "ins_2p5_to_5m_rate", sortable: true },
        {
          text: "2.5M-5M B.Min",
          value: "ins_2p5_to_5m_basic_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M XP",
          value: "ins_2p5_to_5m_excess_protector",
          sortable: true,
        },
        {
          text: "2.5M-5M XP.Min",
          value: "ins_2p5_to_5m_xp_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M PVT",
          value: "ins_2p5_to_5m_pvt_rate",
          sortable: true,
        },
        {
          text: "2.5M-5M PVT.Min",
          value: "ins_2p5_to_5m_pvt_minimum",
          sortable: true,
        },
        {
          text: "2.5M-5M Courtesy",
          value: "ins_2p5_to_5m_courtesy_car",
          sortable: true,
        },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    addNew() {
      this.formData = {};
    },
    setSelectedItem(item) {
      this.formData = item;
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.$touch();
      console.log(this.v$.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      ProviderService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            // Clear modals
            // $("#add-modal").hide();
            // $("body").removeClass("modal-open");
            // $(".modal-backdrop").remove();
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      ProviderService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        ProviderService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal").removeClass("fade").hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return ProviderService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
