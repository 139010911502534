<template>
  <footer class="footer">
    <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
      <div class="col-12 col-sm-auto text-center">
        <p class="mb-0 text-600">
          Thank you for using Bulla
          <span class="d-none d-sm-inline-block">| </span
          ><br class="d-sm-none" />
          {{ year }} &copy;
          <a href="https://bulla.co.ke"
            ><img
              class="me-0 mt-n1"
              src="assets/img/icons/spot-illustrations/bulla.png"
              alt=""
              width="40"
            />
            Softworks Ltd</a
          >
        </p>
      </div>
      <div class="col-12 col-sm-auto text-center">
        <p class="mb-0 text-600">v1.1.0</p>
      </div>
    </div>
    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
  </footer>
</template>

<style>
.v-step {
  color: #2362b7 !important;
  background: #e6effc !important;
}
.v-step__header {
  background: #b6c1d2 !important;
  font-weight: 700 !important;
}
.v-step__arrow::before {
  background: #b6c1d2 !important;
}
.v-step__button[data-v-61f60785] {
  background: transparent;
  border: 0.05rem solid #2362b7 !important;
  border-radius: 0.1px;
  color: #2362b7  !important;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "FooterComp",
  data() {
    return {
      year: new Date().getFullYear(),
      steps: [
        {
          target: "#tour-step-0",
          header: {
            title: "Get Started",
          },
          content: `Discover <strong>The Power of Bulla BI</strong>! <br> Start by filling customer details here.`,
        },
        {
          target: "#tour-step-1",
          header: {
            title: "Quotations",
          },
          content: `Generated <strong>quotations</strong> will appear here.`,
        },
      ],
    };
  },
  created() {},
  mounted() {
    // this.$tours["myTour"].start();
  },
  methods: {},
};
</script>
