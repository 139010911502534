<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="d-inline d-inline">
                <button
                  class="btn btn-falcon-primary btn-sm"
                  type="button"
                  @click="getToday"
                  data-event="today"
                >
                  Today
                </button>
              </div>
            </div>
            <div class="col-auto">
              <flat-pickr
                v-model="formData.from"
                @change="dateChanged"
                class="form-control d-inline"
                placeholder="Select Start"
                style="width: 45%"
              />
              To
              <flat-pickr
                v-model="formData.to"
                @change="dateChanged"
                class="form-control d-inline ml-2"
                placeholder="Select Start"
                style="width: 45%"
              />
            </div>
            <div class="col-auto">
              <button
                class="btn btn-falcon-success btn-sm me-1 mb-2 mb-sm-0"
                type="button"
                @click="generateReport"
              >
                <span class="fas fa-arrow-down me-1"> </span>Download (.pdf)
              </button>
              <button
                class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                type="button"
                @click="printReport"
              >
                <span class="fas fa-print me-1"> </span>Print
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-body">
          <div class="row align-items-center text-center mb-3">
            <div class="col text-sm-center mt-2 mt-sm-0">
              <h4 class="my-2">Payments Analysis Report</h4>
              <h6 class="mb-0">
                {{ user.company.NAME }}, {{ user.company.ADDRESS_LINE1 }}
              </h6>
              <p class="fs--1 mb-0">{{ user.company.ADDRESS_LINE2 }}</p>
              <p class="fs--1 mb-0">{{ user.company.ADDRESS_LINE3 }}</p>
              <p class="fs--1 mb-0">
                Date : {{ formData.from }} To : {{ formData.to }}
              </p>
            </div>
          </div>
          <div class="table-responsive scrollbar mt-1 fs--1">
            <table class="table table-striped border-bottom">
              <thead class="light">
                <tr class="bg-primary text-white dark__bg-1000">
                  <th class="border-0">DATE/TIME</th>
                  <th class="border-0">MODE</th>
                  <th class="border-0">NOTE</th>
                  <th class="border-0">OWNER</th>
                  <th class="border-0 text-end">AMOUNT (KSH)</th>
                </tr>
              </thead>
              <tbody v-if="resources.length>0">
                <tr v-for="(item, i) in resources" :key="i">
                  <td class="mb-0 text-nowrap">
                    {{ formatDate(item.TRANSACTION_TIME) }}
                  </td>
                  <td class="mb-0">{{ item.PAYMENT_TYPE }}</td>
                  <td class="mb-0">{{ item.NOTE }}</td>
                  <td class="mb-0">{{ item.OWNER }}</td>
                  <td class="text-end">
                    {{ formatToCurrency(item.AMOUNT) }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="mb-0 text-nowrap" colspan="5">
                    No records found!
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <table class="table table-sm table-borderless fs--1">
                <tr class="border-bottom border-bottom-2 fw-bolder text-900">
                  <th>Revenue Amount:</th>
                  <td class="text-end">
                    {{ formatToCurrency(summary) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light">
          <p class="fs--1 mb-0">
            <strong>Report by: </strong>Bulla POS Restaurant!
          </p>
        </div>
      </div>

      <!-- FOR PRINTING  -->
      <VueHtml2pdf
        :key="printarea_key"
        :show-layout="false"
        :float-layout="true"
        :enable-download="enable_download"
        :preview-modal="preview_modal"
        :paginate-elements-by-height="1100"
        filename="Sales Analysis Report - Bellalisa"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        :pdf-margin="5"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        ref="html2Pdf"
      >
        <template v-slot:pdf-content>
          <div class="card mb-n5 mx-2" style="margin-top: -1150px">
            <div class="card-body">
              <div class="row align-items-center text-center mb-3">
                <div class="col text-sm-center mt-2 mt-sm-0">
                  <h4 class="my-2">Payments Analysis Report</h4>
                  <h6 class="mb-0">
                    {{ user.company.NAME }}, {{ user.company.ADDRESS_LINE1 }}
                  </h6>
                  <p class="fs--1 mb-0">{{ user.company.ADDRESS_LINE2 }}</p>
                  <p class="fs--1 mb-0">{{ user.company.ADDRESS_LINE3 }}</p>
                  <p class="fs--1 mb-0">
                    Date : {{ formData.from }} To : {{ formData.to }}
                  </p>
                </div>
              </div>
              <div class="table-responsive scrollbar mt-1 fs--1">
                <table class="table table-striped border-bottom">
                  <thead class="light">
                    <tr class="bg-primary text-white dark__bg-1000">
                      <th class="border-0">DATE/TIME</th>
                      <th class="border-0">MODE</th>
                      <th class="border-0">NOTE</th>
                      <th class="border-0">OWNER</th>
                      <th class="border-0 text-end">AMOUNT (KSH)</th>
                    </tr>
                  </thead>
                  <tbody v-if="resources.length>0">
                    <tr v-for="(item, i) in resources" :key="i">
                      <td class="mb-0 text-nowrap">
                        {{ formatDate(item.TRANSACTION_TIME) }}
                      </td>
                      <td class="mb-0">{{ item.PAYMENT_TYPE }}</td>
                      <td class="mb-0">{{ item.NOTE }}</td>
                      <td class="mb-0">{{ item.OWNER }}</td>
                      <td class="text-end">
                        {{ formatToCurrency(item.AMOUNT) }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="mb-0 text-nowrap" colspan="5">
                        No records found!
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <table class="table table-sm table-borderless fs--1">
                    <tr
                      class="border-bottom border-bottom-2 fw-bolder text-900"
                    >
                      <th>Revenue Amount:</th>
                      <td class="text-end">
                        {{ formatToCurrency(summary) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer bg-light">
              <p class="fs--1 mb-0">
                <strong>Report by: </strong>Bulla POS Restaurant!
              </p>
            </div>
          </div>
        </template>
      </VueHtml2pdf>

      <FooterComp />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

const now = new Date();

export default {
  name: "PaymentAnalysisView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
  },
  created() {
    this.getUser();
    this.getAllData();
  },
  mounted() {
    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  data() {
    return {
      summary: [],
      resources: [],
      printarea_key: 1,
      preview_modal: false,
      enable_download: false,
      formData: {
        from: moment(new Date()).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD"),
      },
      config: {
        dateFormat: "M j, Y",
      },
      user: {},
    };
  },
  methods: {
    formatDate(input) {
      return moment(input).format("DD-MM-YYYY HH:mm:ss");
    },

    getUser() {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
      } else {
        this.handleLogout();
      }
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    dateChanged() {
      console.log("Date changed..");
      console.log(this.formData);
      this.getAllData();
    },
    getToday() {
      this.formData.from = this.formData.to = moment(new Date()).format(
        "DD-MM-YYYY"
      );
      this.getAllData();
    },
    printReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = false;
      this.preview_modal = true;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    generateReport() {
      this.$store.commit("SET_LOADING", true);
      this.enable_download = true;
      this.preview_modal = false;
      this.printarea_key += 1;
      this.report_name = "Sales Analysis Report";
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
        this.$store.commit("SET_LOADING", false);
      }, 1000);
    },
    onProgress(e) {
      console.log(e);
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },

    getAllData() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.sales(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            console.log(response.data.data);
            this.resources = response.data.data.records;
            this.summary = response.data.data.summary;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
