<template>
  <div>
    <div
      class="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
    >
      <div class="card-header">
        <div class="row flex-between-center border-bottom pb-1">
          <div class="col-4 col-sm-auto align-items-center pe-0">
            <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              <i class="fa fa-users text-warning"></i>
              Customers
            </h5>
            <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
              Manage all available customers
            </p>
          </div>
          <div class="col-8 col-sm-auto ms-auto text-end ps-0">
            <div id="orders-actions" class="d-flex">
              <div class="search-box h1">
                <input
                  class="form-control search-input fuzzy-search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  v-model="searchValue"
                />
                <span class="fas fa-search search-box-icon"></span>
              </div>

              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-pil h1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-user-plus" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">New</span>
              </button>
              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-pil h1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-file-excel" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Import</span>
              </button>
              <button
                class="btn btn-falcon-warning btn-sm ms-2 rounded-pil h1"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#add-modal"
              >
                <span class="fas fa-file-excel" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Export</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-3 py-0">
        <!-- Menu items table  -->
        <EasyDataTable
          id="tour-step-1"
          table-class-name="customize-table"
          alternating
          buttons-pagination
          :headers="headers"
          :items="resources"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
        >
          <template #loading>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </template>
          <template #item-name="item">
            <div class="my-2">
              <strong>{{ item.name ? item.name.toUpperCase() : "" }}</strong>
              <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
            </div>
          </template>
          <template #item-created_at="item">
            <div class="my-2">
              {{ formatDateHuman(item.created_at) }}
            </div>
          </template>

          <template #item-action="item">
            <div class="dropdown font-sans-serif position-static">
              <button
                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="order-dropdown-0"
                data-bs-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="fas fa-ellipsis-h fs--1"></span>
              </button>
              <div
                class="dropdown-menu dropdown-menu-end border py-0"
                aria-labelledby="order-dropdown-0"
              >
                <div class="py-2">
                  <a
                    class="dropdown-item"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-modal"
                    @click="editItem(item)"
                    >Edit</a
                  >

                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item text-danger"
                    role="button"
                    @click="deleteResource(item)"
                    >Delete</a
                  >
                </div>
              </div>
            </div>
          </template>
        </EasyDataTable>
        <!-- End Menu items table  -->
      </div>
      <div class="card-footer">
        <!-- footer content  -->
      </div>
    </div>
    <!-- Start edit modal  -->
    <div
      class="modal fade"
      id="edit-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="edit-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Edit Customer</h4>
            </div>
            <div class="row my-3 mx-2">
              <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <div class="row">
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >FirstName</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.first_name"
                          :class="
                            v$.formData.first_name.$dirty &&
                            v$.formData.first_name.$invalid
                              ? `is-invalid`
                              : v$.formData.first_name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.first_name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Last Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.last_name"
                          :class="
                            v$.formData.last_name.$dirty &&
                            v$.formData.last_name.$invalid
                              ? `is-invalid`
                              : v$.formData.last_name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.last_name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Email</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.email"
                        />
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Phone</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          disabled
                          v-model="formData.mobile_no"
                        />
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                </div>
                <div class="d-flex">
                  <span class="fa-stack ms-n1 me-3"
                    ><i class="fas fa-circle fa-stack-2x text-200"></i
                    ><i
                      class="
                        fa-inverse fa-stack-1x
                        text-primary
                        fas
                        fa-align-left
                      "
                      data-fa-transform="shrink-2"
                    ></i
                  ></span>
                  <div class="flex-1">
                    <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                    <div class="row">
                      <div class="mb-3 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >KRA PIN:</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <div class="mb-2 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >Log Book</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <div class="mb-2 col-12">
                        <label class="col-form-label" for="recipient-name"
                          >National ID</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="file"
                        />
                      </div>
                      <label class="col-form-label" for="message-text"
                        >Other details</label
                      >
                      <p class="text-word-break fs--1">
                        <textarea
                          v-model="formData.detail"
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                        ></textarea>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <h6 class="mt-5 mt-lg-0">Vehicles</h6>
                <ul class="nav flex-lg-column fs--1">
                  <li
                    class="nav-item me-2 me-lg-0"
                    v-for="(vehicle, i) in formData.vehicles"
                    :key="i"
                  >
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details text-warning"
                      role="button"
                      ><span class="fas fa-dot-circle me-2"></span
                      >{{ vehicle.make }}, {{ vehicle.model }} -
                      {{ vehicle.reg_no }}
                    </a>
                  </li>
                </ul>
                <hr />
                <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                <ul class="nav flex-lg-column fs--1">
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      @click="toggleActive"
                      class="nav-link nav-link-card-details"
                      role="button"
                      ><span class="fa fa-plus me-2"></span>Add Vehicle
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      @click="toggleRoot"
                      class="nav-link nav-link-card-details"
                      role="button"
                    >
                      <span class="fas fa-download me-2"></span>Download KYCs
                    </a>
                  </li>
                  <li class="nav-item me-2 me-lg-0">
                    <a
                      class="nav-link nav-link-card-details"
                      role="button"
                      @click="toggleShowOnMain"
                      ><span class="fas fa-align-left me-2"></span>View
                      History</a
                    >
                  </li>
                  <li class="nav-item me-2 me-lg-0 mt-8">
                    <a
                      class="
                        nav-link nav-link-card-details
                        bg-danger
                        text-white
                      "
                      role="button"
                      @click="deleteResource(formData)"
                      ><span class="fas fa-paperclip me-2"></span>Delete</a
                    >
                  </li>
                  <li class="nav-item me-2 me-lg-0 mt-2">
                    <a
                      class="
                        nav-link nav-link-card-details
                        bg-primary
                        text-white
                      "
                      role="button"
                      @click="updateResource"
                      ><span class="fa fa-align-left me-2"></span>Update
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->
    </div>
    <!-- Start add modal  -->
    <div
      class="modal fade"
      id="add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="
                btn-close btn btn-sm btn-circle
                d-flex
                flex-center
                transition-base
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new customer</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >First Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g John Doe"
                            v-model="formData.first_name"
                            :class="
                              v$.formData.first_name.$dirty &&
                              v$.formData.first_name.$invalid
                                ? `is-invalid`
                                : v$.formData.first_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.first_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="recipient-name"
                            >Last Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g John Doe"
                            v-model="formData.last_name"
                            :class="
                              v$.formData.last_name.$dirty &&
                              v$.formData.last_name.$invalid
                                ? `is-invalid`
                                : v$.formData.last_name.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.last_name.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Email</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge johndoe@gmail.com"
                            v-model="formData.email"
                          />
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Phone</label
                          >
                          <input
                            class="form-control"
                            id="recipient-home"
                            type="text"
                            placeholder="e.g +25471234567"
                            v-model="formData.mobile_no"
                          />
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="
                          fa-inverse fa-stack-1x
                          text-primary
                          fas
                          fa-align-left
                        "
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <!-- <h5 class="mb-2 fs-0">KYCs</h5> -->
                      <div class="row">
                        <div class="mb-3 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >KRA PIN:</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >Log Book</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <div class="mb-2 col-12">
                          <label class="col-form-label" for="recipient-name"
                            >National ID</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div>
                        <label class="col-form-label" for="message-text"
                          >Other details</label
                        >
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.detail"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.active ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleRoot"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.is_root ? `bg-info text-white` : ``"
                      >
                        <span class="fas fa-user me-2"></span>Root/Parent?
                      </a>
                    </li>
                    <li class="nav-item me-2 me-lg-0">
                      <a
                        @click="toggleShowOnMain"
                        class="nav-link nav-link-card-details"
                        role="button"
                        ><span class="fas fa-align-left me-2"></span>Show on
                        Drawer</a
                      >
                    </li>
                    <br />
                    <hr />
                    <li class="nav-item me-2 me-lg-0 mt-auto">
                      <a
                        class="btn btn-primary nav-link nav-link-card-details"
                        role="button"
                        @click="saveResource"
                        ><span class="fa fa-save me-2"></span>Save
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End add modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CustomerService from "../services/customer.service";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        first_name: { required },
        last_name: { required },
        phone: { required },
        email: { required, email },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["name", "mobile_no", "email"],
      headers: [
        { text: "Code", value: "id", sortable: true, widh:10 },
        { text: "Name", value: "name", sortable: true },
        { text: "Phone", value: "mobile_no", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Registered at", value: "created_at", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    // this.forceReload();
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    editItem(item) {
      this.formData = item;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.link.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      CustomerService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            $("#add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.group("customer");
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.$store.commit("SET_LOADING", true);
      CustomerService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            // Clear modals
            $("#edit-modal").removeClass("fade").hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteResource(formData) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        CustomerService.delete(formData.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal").removeClass("fade").hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return CustomerService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
