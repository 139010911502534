import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import BlankView from "../views/BlankView.vue";
import UsersView from "../views/UsersView.vue";
import RolesView from "../views/RolesView.vue";
import ModulesView from "../views/ModulesView.vue";
import CustomersView from "../views/CustomersView.vue";
import DashboardView from "../views/DashboardView.vue";
import OpenOrdersView from "../views/OpenOrdersView.vue";
import SalesReportView from "../views/SalesReportView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import NewApplicationView from "../views/NewApplicationView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import PaymentAnalysisView from "../views/PaymentAnalysisView.vue";
import QuotationSampleView from "../views/QuotationSampleView.vue";
import PremiumProvidersView from "../views/PremiumProvidersView.vue";
import QuotationTemplateView from "../views/QuotationTemplateView.vue";
import ApplicationHistoryView from "../views/ApplicationHistoryView.vue";
import TicketAnalysisReportView from "../views/TicketAnalysisReportView.vue";

const routes = [
  {
    path: "/user.index",
    name: "user.index",
    component: UsersView,
    meta: { layout: "main" },
  },
  {
    path: "/role.index",
    name: "role",
    component: RolesView,
    meta: { layout: "main" },
  },
  {
    path: "/new-application",
    name: "new-application",
    component: NewApplicationView,
    meta: { layout: "main" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { layout: "main" },
  },
  {
    path: "/module.index",
    name: "module.index",
    component: ModulesView,
    meta: { layout: "main" },
  },
  {
    path: "/premium-providers",
    name: "premium-providers",
    component: PremiumProvidersView,
    meta: { layout: "main" },
  },
  {
    path: "/application-history",
    name: "application-history",
    component: ApplicationHistoryView,
    meta: { layout: "main" },
  },
  {
    path: "/quotation/:data?",
    name: "quotation",
    component: QuotationTemplateView,
  },
  {
    path: "/customer.index",
    name: "customer.index",
    component: CustomersView,
    meta: { layout: "main" },
  },
  {
    path: "/quotation-sample",
    name: "quotation-sample",
    component: QuotationSampleView,
    meta: { layout: "main" },
  },
  {
    path: "/premium-providers",
    name: "premium-providers",
    component: PremiumProvidersView,
    meta: { layout: "main" },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/blank",
    name: "blank",
    component: BlankView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "init",
    component: Login,
  },
  {
    path: "/pos-open-order",
    name: "pos-open-order",
    component: OpenOrdersView,
  },

  {
    path: "/sales-analysis-report",
    name: "sales-analysis-report",
    component: SalesReportView,
  },
  {
    path: "/ticket-analysis",
    name: "ticket_analysis",
    component: TicketAnalysisReportView,
  },
  {
    path: "/payment-analysis-report",
    name: "payment_analysis_report",
    component: PaymentAnalysisView,
  },
];

const router = createRouter({
  mode: "history",
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register/enterprise",
    "/register/individual",
    "/register/starter",
    "/register/standard",
    "/home",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
