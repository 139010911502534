import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ProviderService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "provider", { headers: authHeader() });
  }
  history() {
    return axios.get(VUE_APP_BASE_URL + "history", { headers: authHeader() });
  }
  historySummary(){
    return axios.get(VUE_APP_BASE_URL + "history-summary", { headers: authHeader() });
  }
  historyPage(page) {
    return axios.get(VUE_APP_BASE_URL + "history?page="+page, { headers: authHeader() });
  }
  quote(formData) {
    return axios.post(VUE_APP_BASE_URL + "providers-quote", formData, {
      headers: authHeader(),
    });
  }
  quotationData(formData) {
    return axios.post(VUE_APP_BASE_URL + "quotation-data", formData, {
      headers: authHeader(),
    });
  }
  quotationMobileDownload(token) {
    return axios.get(VUE_APP_BASE_URL + "quotation-download/" + token, {
      headers: authHeader(),
    });
  }
  store(formData) {
    return axios.post(VUE_APP_BASE_URL + "provider", formData, {
      headers: authHeader(),
    });
  }
  update(formData) {
    return axios.put(VUE_APP_BASE_URL + "provider/" + formData.id, formData, {
      headers: authHeader(),
    });
  }
  delete(id) {
    return axios.delete(VUE_APP_BASE_URL + "provider/" + id, {
      headers: authHeader(),
    });
  }
  shareSummaryEmail(token) {
    return axios.get(VUE_APP_BASE_URL + "quotation-summary-email/" + token, {
      headers: authHeader(),
    });
  }
}

export default new ProviderService();
