import { createStore } from 'vuex'
import { auth } from './auth.module'
import { alert } from './alert.module'
import { pos } from './pos.module'

export default createStore({
  state: {
    loading: false
  },
  getters: {
    getLoading(state){
      return state.loading
    }
  },
  mutations: {
    SET_LOADING(state, val){
      this.state.loading = val
    }
  },
  actions: {
  },
  modules: {
    auth,
    alert,
    pos
  }
})
